import { FC, ReactElement } from 'react';
import { Divider } from '@mui/material';
import { Event, useEventGuardians } from 'src/repos/events';
import { Guardian } from './Guardian';
import { useTranslation } from 'react-i18next';

type Props = {
  event?: Event;
  chat?: ReactElement;
};

export const Guardians: FC<Props> = (props) => {
  const { event, chat } = props;

  const { t } = useTranslation();
  const { data: guardians } = useEventGuardians(event?.eventId.toString());

  return guardians ? (
    <div className="flex flex-col mt-8 bg-white rounded-lg justify-center text-center drop-shadow-md ">
      <p className="font-semibold py-10 px-8 text-left">
        {t('event.guardians')}
      </p>
      <Divider />
      <div className="px-8 py-6">
        {guardians.length ? (
          guardians.map((guardian, i) => (
            <Guardian
              key={i}
              guardian={guardian}
              chat={chat}
              showChat={!event?.resolvedAt}
            />
          ))
        ) : (
          <p className="font-light italic text-grey-700">
            {t('event.noGuardians')}
          </p>
        )}
      </div>
    </div>
  ) : null;
};
