import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PaginatedApiParams, PaginatedApiResponse } from 'src/app/api';
import { RootState } from 'src/app/store';
import { Event, EventsSearch, getEvents } from 'src/repos/events';

export type EventsState = {
  events: PaginatedApiResponse<Event> | null;
  status?: 'idle' | 'loading' | 'succeeded' | 'failed';
  error?: string | null;
  filters: EventsSearch;
};

export type FetchEventsParams = {
  filters: EventsSearch;
  params: PaginatedApiParams;
};

export const eventsSlice = createSlice({
  name: 'users',
  initialState: {
    events: null,
    status: 'idle',
    error: null,
    filters: {
      active: true,
    },
  } as EventsState,
  reducers: {
    setFilter: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    removeFilter: (state, action) => {
      delete state.filters[action.payload as keyof EventsSearch];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.events = action.payload;
      })
      .addCase(fetchEvents.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchEvents.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const fetchEvents = createAsyncThunk(
  'events/fetchEvents',
  async (searchParams: FetchEventsParams, { getState }) => {
    const { filters, params } = searchParams;

    return await getEvents(filters, params);
  }
);

export const { setFilter, removeFilter } = eventsSlice.actions;

// selectors
export const selectEvents = (state: RootState) => state.events.events;
export const selectPageSize = (state: RootState) =>
  state.events.events?.pageable.pageSize;
export const selectPageIndex = (state: RootState) =>
  state.events.events?.pageable.pageNumber;
export const selectPagesCount = (state: RootState) =>
  state.events.events?.totalPages;
export const fetchEventsStatus = (state: RootState) => state.events.status;
export const selectFilters = (state: RootState) => state.events.filters;

export default eventsSlice.reducer;
