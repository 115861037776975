import {
  Event,
  People,
  Public,
  WidgetsOutlined,
  ReportGmailerrorredOutlined,
  SettingsOutlined,
} from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { Sticky } from './styles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const SideNav = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <Sticky className="flex flex-col min-w-[94px] sticky">
      <div className="bg-primary flex flex-row justify-center p-3 min-h-[88px]">
        <Link to="/map">
          <img
            className="min-w-[64px]"
            src="/sideNavLogo.png"
            alt="sideNavLogo"
          />
        </Link>
      </div>
      {/* <div className="mt-10 mb-5">
        <Link
          className={clsx(
            pathname === '/' ? 'text-primary' : 'text-grey-700',
            'flex flex-col justify-center items-center'
          )}
          to="/"
        >
          <WidgetsOutlined />
          <p className="text-sm">{t('general.home')}</p>
        </Link>
      </div> */}
      <div className="my-5">
        <Link
          className={clsx(
            pathname === '/map' ? 'text-primary' : 'text-grey-700',
            'flex flex-col justify-center items-center'
          )}
          to="/map"
        >
          <Public />
          <p className="text-sm">{t('general.map')}</p>
        </Link>
      </div>
      <div className="my-5">
        <Link
          className={clsx(
            pathname === '/users' ? 'text-primary' : 'text-grey-700',
            'flex flex-col justify-center items-center'
          )}
          to="/users"
        >
          <People />
          <p className="text-sm">{t('users.title')}</p>
        </Link>
      </div>
      <div className="my-5">
        <Link
          to="/events"
          className={clsx(
            pathname.includes('event') ? 'text-primary' : 'text-grey-700',
            'flex flex-col justify-center items-center'
          )}
        >
          <Event />
          <p className="text-sm">{t('events.title')}</p>
        </Link>
      </div>
      <div className="my-5">
        <Link
          className={clsx(
            pathname === '/reports' ? 'text-primary' : 'text-grey-700',
            'flex flex-col justify-center items-center'
          )}
          to="/reports"
        >
          <ReportGmailerrorredOutlined />
          <p className="text-sm">{t('reports.title')}</p>
        </Link>
      </div>
      <div className="my-5">
        <Link
          className={clsx(
            pathname === '/settings' ? 'text-primary' : 'text-grey-700',
            'flex flex-col justify-center items-center'
          )}
          to="/settings"
        >
          <SettingsOutlined />
          <p className="text-sm">{t('settings.title')}</p>
        </Link>
      </div>
    </Sticky>
  );
};
