import axios, { AxiosRequestConfig } from 'axios';
import { auth } from 'src/config/firebase';
import { LOCAL_STORAGE_AUTH_TOKEN_KEY } from 'src/components/ProtectedRoute';

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_CLICKNOW_API_URL as string,
});

axiosInstance.defaults.headers.common = {
  'Cache-Control': 'no-cache',
  'Content-Type': 'application/json',
};

axiosInstance.interceptors.request.use(
  async (config) => {
    const authToken: string | undefined =
      window?.localStorage?.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY) || undefined;

    if (authToken && config.headers && !config.url?.includes('.s3.')) {
      // upload to s3 doesn't required token
      config.headers['Authorization'] = 'Bearer ' + authToken;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error?.config;
    if (error?.response?.status === 401 && !originalRequest?._retry) {
      originalRequest._retry = true;
      const token = await auth.currentUser?.getIdToken();

      window?.localStorage?.setItem(
        LOCAL_STORAGE_AUTH_TOKEN_KEY,
        token as string
      );

      return axiosInstance({
        ...originalRequest,
        headers: {
          ...originalRequest.headers,
          Authorization: `Bearer ${token}`,
        },
        sent: true,
      });
    }
    return Promise.reject(error);
  }
);

export const api = {
  get: (url: string, config?: AxiosRequestConfig) =>
    axiosInstance.get(url, config),
  post: (url: string, data: unknown, config?: AxiosRequestConfig) =>
    axiosInstance.post(url, data, config),
  put: (url: string, data: unknown, config?: AxiosRequestConfig) =>
    axiosInstance.put(url, data, config),
  delete: (url: string, data: unknown, config?: AxiosRequestConfig) =>
    axiosInstance.delete(url, { ...config, data }),
  patch: (url: string, data: unknown, config?: AxiosRequestConfig) =>
    axiosInstance.patch(url, data, config),
  head: (url: string) => axiosInstance.head(url),
};

export type PaginatedApiResponse<T> = {
  content: T[];
  totalPages: number;
  totalElements: number;
  pageable: {
    offset: number;
    pageNumber: number;
    pageSize: number;
  };
};

export type PaginatedApiParams = {
  page: number;
  size: number;
};
