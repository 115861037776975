import { Avatar, IconButton } from '@mui/material';
import { FC, ReactElement } from 'react';
import { ActiveGuardian } from 'src/repos/events';
import { LocalPhoneOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useTwilioContext } from 'src/components/TwilioContextProvider';

type Props = {
  guardian: ActiveGuardian;
  chat?: ReactElement;
  showChat: boolean;
};

export const Guardian: FC<Props> = (props) => {
  const { guardian, chat, showChat } = props;

  const { t } = useTranslation();
  const { startCall } = useTwilioContext();

  return (
    <div className="flex flex-row justify-between text-center py-3">
      <div className="flex flex-row items-start">
        <Avatar
          src={guardian?.imageUrl || ''}
          alt={`guard#${guardian.activeGuardianId}`}
        />
        <p className="font-bold ltr:max-2xl:ml-3 rtl:max-2xl:mr-3 ltr:ml-5 rtl:mr-5 ">
          {guardian?.name || t('general.unknown')}
        </p>
      </div>
      <div className="flex flex-row items-center justify-end">
        <IconButton
          onClick={() => {
            startCall(guardian.userId.toString());
          }}
          className="!p-0 ltr:mr-2 rtl:ml-2"
        >
          <LocalPhoneOutlined />
        </IconButton>
        {showChat ? (chat ? chat : null) : null}
      </div>
    </div>
  );
};
