import { initializeApp, getApp } from 'firebase/app';
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  onIdTokenChanged,
} from 'firebase/auth';
import { getMessaging, getToken } from 'firebase/messaging';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

initializeApp(config, `clicknow-app`);

export const messaging = getMessaging(getApp('clicknow-app'));
const auth = getAuth(getApp('clicknow-app'));

export const getMessagingToken = () => {
  return getToken(messaging, {
    vapidKey: process.env.REACT_APP_FIREBASE_MESSAGING_KEY,
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        //setTokenFound(true);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          'No registration token available. Request permission to generate one.'
        );
        //setTokenFound(false);
        // shows on the UI that permission is required
      }
      return currentToken;
    })
    .catch((err: any) => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
};

export {
  auth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  onIdTokenChanged,
};
