import clsx from 'clsx';
import { FC, ReactElement } from 'react';
import { LocalPhoneTwoTone, MessageTwoTone } from '@mui/icons-material';
import { User } from 'src/repos/users';
import { Avatar, IconButton } from '@mui/material';
import { useTwilioContext } from 'src/components/TwilioContextProvider';

type Props = {
  user: User;
  className?: string;
  chat?: ReactElement;
  showChat?: boolean;
};

export const UserActionBar: FC<Props> = (props) => {
  const { user, className = 'bg-primary', chat, showChat = true } = props;
  const { startCall, startChat } = useTwilioContext();

  return (
    <div
      className={clsx(
        className,
        'rounded-b-lg basis-1/6 py-4 flex flex-row items-center justify-between'
      )}
    >
      <div className="flex flex-row">
        <Avatar
          sx={{ width: 56, height: 56 }}
          variant="square"
          src={user?.imageUrl || ''}
          alt={`${user?.userId}-avatar`}
        />
        <div className="flex flex-col ltr:max-2xl:ml-3 rtl:max-2xl:mr-3 ltr:ml-5 rtl:mr-5 text-left">
          <p className="mb-1 text-white text-ellipsis">
            {user?.name || 'Default Name'}
          </p>
          <p className="text-white/60">{'User'}</p>
        </div>
      </div>
      <div>
        <IconButton
          onClick={() => {
            startCall(user.userId.toString());
          }}
          className="!p-0 ltr:max-2xl:!mr-2 rtl:max-2xl:!ml-2 ltr:!mr-5 rtl:!ml-5"
        >
          <LocalPhoneTwoTone className="text-white" />
        </IconButton>
        {showChat ? (
          chat ? (
            chat
          ) : (
            <IconButton
              onClick={async () => {
                startChat({
                  id: user.userId.toString(),
                });
              }}
              className="!p-0"
            >
              <MessageTwoTone className="text-white" />
            </IconButton>
          )
        ) : null}
      </div>
    </div>
  );
};
