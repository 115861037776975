import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PaginatedApiParams, PaginatedApiResponse } from 'src/app/api';
import { RootState } from 'src/app/store';
import { Report, ReportsSearch, getReports } from 'src/repos/reports';

export type ReportsState = {
  reports: PaginatedApiResponse<Report> | null;
  status?: 'idle' | 'loading' | 'succeeded' | 'failed';
  error?: string | null;
  filters: ReportsSearch;
};

export type FetchReportsParams = {
  filters: ReportsSearch;
  params: PaginatedApiParams;
};

export const reportsSlice = createSlice({
  name: 'users',
  initialState: {
    reports: null,
    status: 'idle',
    error: null,
    filters: {},
  } as ReportsState,
  reducers: {
    setFilter: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    removeFilter: (state, action) => {
      delete state.filters[action.payload as keyof ReportsSearch];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchReports.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.reports = action.payload;
      })
      .addCase(fetchReports.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchReports.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const fetchReports = createAsyncThunk(
  'reports/fetchReports',
  async (searchParams: FetchReportsParams, { getState }) => {
    const { filters, params } = searchParams;

    return await getReports(filters, params);
  }
);

export const { setFilter, removeFilter } = reportsSlice.actions;

// selectors
export const selectReports = (state: RootState) => state.reports.reports;
export const selectPageSize = (state: RootState) =>
  state.reports.reports?.pageable.pageSize;
export const selectPageIndex = (state: RootState) =>
  state.reports.reports?.pageable.pageNumber;
export const selectPagesCount = (state: RootState) =>
  state.reports.reports?.totalPages;
export const fetchReportsStatus = (state: RootState) => state.reports.status;
export const selectFilters = (state: RootState) => state.reports.filters;

export default reportsSlice.reducer;
