import { useQuery } from 'react-query';
import { api } from 'src/app/api';

export type TwilioToken = {
  accessToken: string;
  identity: string;
  issuedAt: string;
};

export type TwilioIdentity = {
  userId: number;
  name: string | null;
  imageUrl: string | null;
  role: string;
};

export const getTwilioToken = async (): Promise<TwilioToken> => {
  const response = await api.get('/twilio/token');

  return response.data;
};

export const getTwilioIdentity = async (
  userId: string | undefined
): Promise<TwilioIdentity | null> => {
  if (!userId) return null;

  const response = await api.get(`/twilio/identity/${userId}`);

  return response.data;
};

export const useTwilioToken = () => {
  return useQuery(`twilioToken`, () => getTwilioToken(), {
    staleTime: 120000,
  });
};

export const useTwilioIdentity = (
  userId: string | undefined,
  enabled = true
) => {
  return useQuery(`twilioIdentity#${userId}`, () => getTwilioIdentity(userId), {
    enabled,
    staleTime: 120000,
  });
};
