import { useMutation, useQuery, useQueryClient } from 'react-query';
import { api, PaginatedApiResponse, PaginatedApiParams } from 'src/app/api';

export type UsersSearch = {
  userId?: string | null;
  name?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  address?: string | null;
  gender?: string | null;
};

export type User = {
  address: string | null;
  birthday: string | null;
  contactSync: boolean;
  email: string;
  enableCommunity: boolean;
  gender: string;
  guardianCount: number;
  imageUrl: string | null;
  locale: 'en' | 'he' | null;
  locationTracking: boolean;
  name: string | null;
  phoneNumber: string | null;
  profileComplete: boolean;
  commandControlEnabled: boolean;
  role: string;
  userId: number;
};

export type FirebaseMessagingToken = {
  fcmToken: string;
};

const usersUrl = (params: PaginatedApiParams) => {
  const { page, size } = params;
  return `/users/search?page=${page}&size=${size}`;
};

const userUrl = (id: number) => {
  return `/users/${id}`;
};

export const getCurrentUser = async (): Promise<User> => {
  const response = await api.get('/users/me');

  return response.data;
};

export const putCurrentUser = async (data: Partial<User>): Promise<User> => {
  const response = await api.put('/users/me', data);

  return response.data;
};

export const registerCurrentUser = async (data: FirebaseMessagingToken) => {
  const response = await api.put('/users/me/fcmtoken', data);

  return response.data;
};

export const unregisterCurrentUser = async (data: FirebaseMessagingToken) => {
  console.log(data, 'unregisterr');

  const response = await api.delete('/users/me/fcmtoken', data);

  return response.data;
};

export const getUsers = async (
  data: UsersSearch,
  params: PaginatedApiParams
): Promise<PaginatedApiResponse<User>> => {
  const response = await api.post(usersUrl(params), data);

  return response.data;
};

export const getUser = async (id: number | undefined): Promise<User | null> => {
  if (!id) return null;
  const response = await api.get(userUrl(id));

  return response.data;
};

export const useCurrentUser = () => {
  return useQuery('currentUser', () => getCurrentUser());
};

export const useUpdateCurrentUser = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: Partial<User & { avatar: string | null }>) => putCurrentUser(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('currentUser');
      },
    }
  );
};

export const useUser = (id: number | undefined) => {
  return useQuery(`user#${id}`, () => getUser(id));
};

export const useRegisterUser = () => {
  return useMutation(
    (data: FirebaseMessagingToken) => registerCurrentUser(data),
    {}
  );
};

export const useUnregisterUser = () => {
  return useMutation((data: FirebaseMessagingToken) =>
    unregisterCurrentUser(data)
  );
};
