import styled from '@emotion/styled';
import { LinearProgress } from '@mui/material';

export const LoaderStyled = styled(LinearProgress)`
  .MuiLinearProgress-bar1Indeterminate {
    background-color: #2025ee; //primary
  }
  .MuiLinearProgress-bar2Indeterminate {
    background-color: #2025ee; //primary
  }
`;
