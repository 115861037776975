import { onMessage } from 'firebase/messaging';
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { messaging } from 'src/config/firebase';
import { toast } from 'react-toastify';
import { NotificationContent } from '../Notification/Content';
import { useUserContext } from '../ProtectedRoute';

type Type =
  | 'REPORT'
  | 'EVENT'
  | 'EVENT_RESOLVED'
  | 'EVENT_UPDATED'
  | 'PROTECTION';

export type NotificationType = {
  data: {
    id: number;
    title?: string;
    owner?: boolean;
    type: Type;
    createdAt: string;
  };
  messageId: string;
  createdAt?: Date;
};

type Props = {
  children: ReactNode;
};

type NotificationsContextState = {
  notifications: NotificationType[];
};

const NotificationsContext = createContext<
  NotificationsContextState | undefined
>(undefined);

export const useNotificationsContext = () => {
  const context = useContext(NotificationsContext);
  if (context === undefined || context === null) {
    throw new Error(
      `useNotificationsContext must be used within an NotificationsContextProvider`
    );
  }
  return context;
};

export const getNotificationRedirectUrl = (notification: NotificationType) => {
  if (notification?.data?.type === 'REPORT') {
    return `/reports?id=${notification?.data?.id}`;
  }

  if (notification?.data?.type === 'PROTECTION') {
    return `/map`;
  }

  if (
    ['EVENT', 'EVENT_RESOLVED', 'EVENT_UPDATED'].includes(
      notification?.data?.type
    )
  )
    return `/events/${notification?.data?.id}`;
};

export const NotificationWrapper: FC<Props> = (props) => {
  const { children } = props;
  const audioPlayer = useRef<HTMLAudioElement>(null);
  const [notifications, setNotifications] = useState<NotificationType[]>([]);
  const { user } = useUserContext();

  const showNotification = (notification: NotificationType) => {
    audioPlayer?.current?.play();

    setNotifications([
      ...notifications,
      { ...notification, createdAt: new Date() },
    ]);

    const url = getNotificationRedirectUrl(notification);

    toast.info(
      <div
        className="flex flex-row items-center cursor-pointer"
        onClick={() => url && window.open(url, '_blank')}
      >
        <NotificationContent
          notification={notification}
          locale={user?.locale || 'en'}
        />
      </div>,
      {
        position: 'top-right',
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        icon: false,
        theme: 'light',
      }
    );
  };

  useEffect(() => {
    const unsubscribe = onMessage(messaging, (message) => {
      showNotification(message as unknown as NotificationType);
    });

    return () => unsubscribe();
  }, []);

  return (
    <NotificationsContext.Provider value={{ notifications }}>
      {children}
      <audio ref={audioPlayer} src={'/notification-126507.mp3'} />
    </NotificationsContext.Provider>
  );
};
