import { useQuery } from 'react-query';
import { api, PaginatedApiResponse, PaginatedApiParams } from 'src/app/api';

export type Location = {
  address: string;
  disabled: boolean;
  latitude: number;
  locationId: number;
  longitude: number;
  name: string;
  radius: number;
  type: 'DANGEROUS_AREA';
};

const dangerousLocationsUrl = (params: PaginatedApiParams) => {
  const { page, size } = params;
  return `/locations/dangerous?page=${page}&size=${size}`;
};

export const getDangerousLocations = async (
  params: PaginatedApiParams
): Promise<Location[]> => {
  const response = await api.get(dangerousLocationsUrl(params));

  return response.data;
};

export const useDangerousLocations = (params: PaginatedApiParams) => {
  return useQuery(`dangerousLocations#${dangerousLocationsUrl(params)}`, () =>
    getDangerousLocations(params)
  );
};
