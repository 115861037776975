import { CallEndTwoTone, CallTwoTone, Close } from '@mui/icons-material';
import {
  Avatar,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  SelectChangeEvent,
  Slider,
  TextField,
} from '@mui/material';
import Select, { SingleValue } from 'react-select';

import { Call } from '@twilio/voice-sdk';
import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { secondsToTime } from 'src/app/utils';
import { useAvatars, Avatar as AvatarType } from 'src/repos/avatar';
import { useTwilioIdentity } from 'src/repos/twilio';
import { DialogStyled } from '../Chat/styles';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { Loader } from '../ProtectedRoute/Loader';
import { Controller, useForm } from 'react-hook-form';
import { useCreateCommunityEvent } from 'src/repos/events';
import { useCreateEventReport, useReportsTypes } from 'src/repos/reports';
import { values } from './utils';
import { useCurrentUser } from 'src/repos/users';
import { SelectOption } from '../ReportFormDialog';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './validation';
import { LoadingButton } from '@mui/lab';

type Props = {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

type EventInput = {
  location: google.maps.GeocoderResult;
  radius: number;
  type: string;
  message: string;
};

export const EventDialog: FC<Props> = (props) => {
  const { onClose, onSuccess, open } = props;
  const { t } = useTranslation();

  const {
    data: event,
    mutate: sendEvent,
    isLoading,
  } = useCreateCommunityEvent();
  const { data: user } = useCurrentUser();
  const { mutate: createEventReport, isLoading: isCreatingEventReport } =
    useCreateEventReport(event?.eventId);
  const { data: types } = useReportsTypes(user?.locale || 'en');

  const options: SelectOption[] = useMemo(
    () =>
      types?.map((option, i) => ({
        value: option,
        label: option,
      })) || [],
    [types]
  );

  const GoogleMapRef = useRef<HTMLDivElement>(null);
  const [libraries] = useState<
    ('places' | 'drawing' | 'geometry' | 'localContext' | 'visualization')[]
  >(['places']);
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACE_API_KEY as string,
    libraries,
  });

  const [zoom, setZoom] = useState(11);
  const [map, setMap] = useState<google.maps.Map | null>(null);

  const [center, setCenter] = useState({ lat: 32.1, lng: 34.829825 });
  const [circle, setCircle] = useState<google.maps.Circle | undefined>();

  const onLoad = useCallback(function callback(map: google.maps.Map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map: google.maps.Map) {
    setMap(null);
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isDirty, isValid },
    setValue,
    getValues,
  } = useForm<EventInput>({
    defaultValues: {
      radius: 0,
    },
    resolver: yupResolver(schema(t)),
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });

  useEffect(() => {
    if (map && center) {
      setCenter(center);
    }
  }, [map, center]);

  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  const onSubmit = (values: EventInput) => {
    sendEvent(
      {
        radius: values.radius,
        totalDuration: 48,
        latitude: center.lat,
        longitude: center.lng,
        message: values.message,
      },
      {
        onSuccess: (event) => {
          createEventReport(
            {
              data: {
                type: values.type,
                summary: values.message,
                latitude: center.lat,
                longitude: center.lng,
              },
              eventId: event?.eventId,
            },
            {
              onSuccess: () => {
                onSuccess();
                setCenter({ lat: 32.1, lng: 34.829825 });
                setZoom(11);
                reset({
                  message: undefined,
                  type: undefined,
                  location: undefined,
                  radius: 0,
                });
                onClose();
              },
            }
          );
        },
      }
    );
  };

  return (
    <DialogStyled
      onClose={() => {
        onClose();
      }}
      open={open}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle className="text-center flex flex-row justify-between items-center">
        <p>{t('event.dialog.title')}</p>
        {onClose ? (
          <IconButton aria-label="close" onClick={onClose}>
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent dividers style={{ height: 600 }}>
        {isLoaded ? (
          <form
            id="event-form"
            className="flex flex-row"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex grow flex-col ltr:pr-8 rtl:pl-8">
              <Controller
                name="location"
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <>
                    <label className="mt-6 mb-4 text-grey-700" htmlFor="email">
                      {t('report.location')}
                    </label>
                    <GooglePlacesAutocomplete
                      apiKey={
                        process.env.REACT_APP_GOOGLE_PLACE_API_KEY as string
                      }
                      apiOptions={{ libraries: ['places'] }}
                      debounce={200}
                      ref={ref}
                      selectProps={{
                        //value,
                        className: 'w-[350px] max-w-[350px]',
                        onChange: (location) => {
                          geocodeByPlaceId(location.value.place_id || '').then(
                            ([res]) => {
                              onChange(res);
                              setCenter({
                                lat: res.geometry.location.lat(),
                                lng: res.geometry.location.lng(),
                              });
                              if (circle) {
                                circle.setCenter({
                                  lat: res.geometry.location.lat(),
                                  lng: res.geometry.location.lng(),
                                });
                              }
                            }
                          );
                        },
                      }}
                    />
                    <span className="text-sm text-red-500 mx-2 mt-2">
                      {errors?.location?.message}
                    </span>
                  </>
                )}
              />

              <Controller
                name="radius"
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <>
                    <label className="mt-6 mb-4 text-grey-700" htmlFor="email">
                      {t('event.dialog.radius')}
                    </label>
                    <div className="px-3">
                      <Slider
                        min={1}
                        max={100}
                        step={1}
                        value={value}
                        ref={ref}
                        disabled={!location}
                        onChange={(e: Event, newValue: number | number[]) => {
                          onChange(newValue as number);

                          if (circle) {
                            const { scaledValue = newValue } = {
                              ...values.find(
                                (value) => value.value === newValue
                              ),
                            };

                            if (newValue < 5) setZoom(15);
                            if (newValue > 5 && newValue < 10) setZoom(14);
                            if (newValue > 10 && newValue < 50) setZoom(12);
                            if (newValue > 50 && newValue < 90) setZoom(10);
                            if (newValue > 90) setZoom(8);

                            circle.setRadius(scaledValue as number);
                          } else {
                            setCircle(
                              new google.maps.Circle({
                                strokeColor: '#FF0000',
                                strokeOpacity: 0.8,
                                strokeWeight: 2,
                                fillColor: '#FF0000',
                                fillOpacity: 0.35,
                                map,
                                center: center,
                                radius: newValue as number,
                              })
                            );
                          }
                        }}
                        valueLabelFormat={(val: number) =>
                          values.find((value) => value.value === val)?.label
                        }
                        valueLabelDisplay="auto"
                      />
                    </div>
                  </>
                )}
              />
              <Controller
                name="type"
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <>
                    <label className="mt-6 mb-4 text-grey-700" htmlFor="type">
                      {t('event.type')}
                    </label>
                    <Select
                      isRtl={user?.locale === 'he'}
                      ref={ref}
                      placeholder={t('report.tellUs')}
                      value={
                        value
                          ? ({ value: value, label: value } as SelectOption)
                          : null
                      }
                      onChange={(option: SingleValue<SelectOption>) => {
                        if (option) {
                          onChange(option.value);
                        }
                      }}
                      isSearchable={true}
                      formatOptionLabel={({ value }) => <span>{value}</span>}
                      options={options}
                    />
                    <span className="text-sm text-red-500 mx-2 mt-2">
                      {errors?.type?.message}
                    </span>
                  </>
                )}
              />
              <div className="flex flex-col">
                <label className="mt-6 mb-4 text-grey-700" htmlFor="email">
                  {t('report.description')}
                </label>
                <TextField
                  multiline
                  minRows={4}
                  className="!border-2"
                  placeholder={t('report.what') as string}
                  id="summary"
                  type="text"
                  data-testid="summary-input"
                  variant="outlined"
                  error={!!errors?.message?.message}
                  helperText={errors?.message?.message}
                  {...register('message')}
                />
              </div>
            </div>
            <div className="flex grow" style={{ width: 500, height: 500 }}>
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={zoom}
                onLoad={onLoad}
                onUnmount={onUnmount}
                options={{
                  minZoom: 3,
                  maxZoom: 17,
                }}
              ></GoogleMap>
            </div>
          </form>
        ) : (
          <Loader />
        )}
      </DialogContent>
      <DialogActions className="!p-7 flex flex-row ">
        <Button
          className="!bg-primary/10 !text-primary !py-2 !px-7 text-center !normal-case"
          onClick={() => {
            // reset({ summary: '', type: '' });
            // setAttachmentError(false);
            // setAttachments([]);
            // attachmentsDisplay.forEach(
            //   (file: { url: string; type: 'img' | 'video' }) =>
            //     URL.revokeObjectURL(file.url)
            // );
            // setAttachmentsDisplay([]);
            // onClose();
          }}
          disabled={isLoading}
        >
          {t('general.cancel')}
        </Button>

        <LoadingButton
          form="event-form"
          className="!bg-primary !text-white !py-2 !px-7 text-center !normal-case rtl:mr-2 rtl:!ml-0"
          type="submit"
          disabled={isLoading && !isValid}
          loading={isLoading || isCreatingEventReport}
        >
          {t('event.send')}
        </LoadingButton>
      </DialogActions>
    </DialogStyled>
  );
};
