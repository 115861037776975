import { Avatar } from '@mui/material';
import { Message } from '@twilio/conversations';
import clsx from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { compareDates } from 'src/app/utils';
import { useTwilioIdentity } from 'src/repos/twilio';

type Props = {
  messages: Message[];
  message: Message;
  index: number;
  curentUserId: string | undefined;
};

export const MessageComponent: FC<Props> = (props) => {
  const { message, messages, index, curentUserId } = props;
  const { t } = useTranslation();
  const { data: author } = useTwilioIdentity(
    message?.author || undefined,
    message.author !== 'system'
  );

  const nextMessage = messages.length > index + 1 ? messages[index + 1] : null;

  return (
    <div
      className={clsx(
        'flex items-end',
        nextMessage?.author !== message.author ? 'mb-4' : 'mb-[5px]',
        message.author === 'system'
          ? 'justify-center'
          : message.author === curentUserId
          ? 'justify-end'
          : 'justify-start'
      )}
    >
      {author && author?.userId?.toString() != curentUserId ? (
        message.author != nextMessage?.author ? (
          <Avatar
            className="mx-2"
            src={author?.imageUrl || ''}
            alt={`participant#${author.userId}`}
            sx={{ width: 48, height: 48 }}
          />
        ) : (
          <div className="mx-8"></div>
        )
      ) : null}
      <div className="flex flex-col">
        {author ? (
          <span
            className={clsx(
              message.author === 'system'
                ? 'text-center'
                : message.author === curentUserId
                ? 'text-right'
                : 'text-left',
              'text-xs text-secondary/60 mt-[10px]'
            )}
          >
            {author?.name || t('general.unknown')}
          </span>
        ) : null}
        <span
          className={clsx(
            message.author === 'system'
              ? 'bg-grey-700/25 text-black rounded-b-xl py-2 px-3'
              : message.author === curentUserId
              ? 'bg-primary text-white rounded-bl-xl py-4 px-6 '
              : 'bg-grey-200 text-black rounded-br-xl py-4 px-6 ',
            'text-sm rounded-t-xl'
          )}
        >
          {message.body}
        </span>
        {message.author != nextMessage?.author ||
        (message.author === nextMessage?.author &&
          compareDates(
            message.dateCreated,
            nextMessage?.dateCreated,
            60000 * 5
          ) < 0) ? (
          <span
            className={clsx(
              message.author === 'system'
                ? 'text-center'
                : message.author === curentUserId
                ? 'text-right'
                : 'text-left',
              'text-xs text-secondary/60 mt-[10px]'
            )}
          >
            {message.dateCreated?.toLocaleTimeString('en', {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </span>
        ) : null}
      </div>
    </div>
  );
};
