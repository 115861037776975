import clsx from 'clsx';
import { FC, useState } from 'react';
import { Button } from '@mui/material';
import { ReportComponent } from './Report';
import { useTranslation } from 'react-i18next';
import { Event, useResolveEvent } from 'src/repos/events';
import { ReportDialog } from './Dialog';
import { Report } from 'src/repos/reports';
import { dateToLocaleString } from 'src/app/utils';
import { ReportFormDialog } from '../ReportFormDialog';
import { useUserContext } from '../ProtectedRoute';

type Props = {
  event?: Event;
  className?: string;
  reports?: Report[] | null;
};

export const Reports: FC<Props> = (props) => {
  const { className, event, reports } = props;
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [currentReport, setCurrentReport] = useState<Report | undefined>();

  const { user } = useUserContext();

  const { mutate: resolveEvent } = useResolveEvent(event?.eventId);

  return (
    <>
      <div
        className={clsx(
          className,
          'flex flex-col justify-start text-center rounded-lg bg-white drop-shadow-md p-7'
        )}
      >
        <div className="flex flex-row justify-between items-center">
          <p className="text-lg font-bold">{t('event.reports')}</p>

          <div className="flex flex-row justify-end items-center">
            {event?.resolvedAt ? (
              <div className="bg-green-500/50 rounded-xl p-2 mx-2">
                <span>
                  {t('event.ended', {
                    resolvedAt: dateToLocaleString(
                      event.resolvedAt,
                      user?.locale
                    ),
                  })}
                </span>
              </div>
            ) : (
              <Button
                className="!bg-primary/10 !text-primary !mx-2"
                variant="contained"
                onClick={() => {
                  resolveEvent();
                }}
              >
                {t('event.end')}
              </Button>
            )}
            <div>
              <Button
                variant="contained"
                onClick={() => {
                  setOpenForm(true);
                }}
              >
                {t('report.send')}
              </Button>
            </div>
            {/* <IconButton className="!pr-0">
              <MoreVert />
            </IconButton> */}
          </div>
        </div>
        {reports?.length ? (
          <div className="flex flex-col justify-start overflow-auto">
            {reports?.map((report, i) => (
              <ReportComponent
                report={report}
                key={i}
                className="my-4"
                onClick={() => {
                  setCurrentReport(report);
                  setOpen(true);
                }}
              />
            ))}
          </div>
        ) : (
          <div className="flex flex-col h-screen justify-center items-center text-grey-700 text-xl italic">
            {t('event.noReports')}
          </div>
        )}
      </div>
      <ReportDialog
        open={open}
        report={currentReport}
        onClose={() => {
          setOpen(false);
          setCurrentReport(undefined);
        }}
      />
      <ReportFormDialog
        open={openForm}
        onClose={() => {
          setOpenForm(false);
        }}
        event={event}
      />
    </>
  );
};
