import { useMutation, useQuery } from 'react-query';
import { api, PaginatedApiResponse, PaginatedApiParams } from 'src/app/api';

export type ReportsSearch = {
  reportId?: number | null;
  eventId?: number | null;
  userId?: number | null;
  assignedBy?: string | null;
  address?: string | null;
  summary?: string | null;
  linked?: boolean | null;
  active?: boolean | null;
};

export type ReportAttachmentUpload = {
  objectKey: string;
  uploadUrl: string;
};

export type ReportAttachmentInput = {
  reportId: number;
  attachmentKey: string;
};

export type Report = {
  reportId: number;
  userId: number;
  tenantId: number;
  eventId: number;
  type: string;
  assignedById: number;
  geo: {
    type: string;
    coordinates: number[];
  };
  latitude: number;
  longitude: number;
  address: string;
  summary: string;
  attachments: {
    attachmentKey: string;
    attachmentUrl: string;
  }[];
  userName: string | null;
  userImageUrl: string | null;
  createdAt: string;
  updatedAt: string;
};

const reportsUrl = (params: PaginatedApiParams) => {
  const { page, size } = params;
  return `/reports/search?page=${page}&size=${size}`;
};

const reportsTypeUrl = (locale: string) => {
  return `/reports/types?locale=${locale}`;
};

const reportUrl = (id: number) => {
  return `/reports/${id}`;
};

const createReportUrl = () => {
  return `/reports`;
};

const createReportAttachmentUrl = (id: number | undefined) => {
  return `/reports/${id}/attachment`;
};

const createEventReportUrl = (eventId: number | undefined) => {
  return `/events/${eventId}/reports`;
};

export const getReports = async (
  data: ReportsSearch,
  params: PaginatedApiParams
): Promise<PaginatedApiResponse<Report>> => {
  const response = await api.post(reportsUrl(params), data);

  return response.data;
};

export const getReportsTypes = async (locale: string): Promise<string[]> => {
  const response = await api.get(reportsTypeUrl(locale));

  return response.data;
};

export const getReport = async (
  id: number | undefined
): Promise<Report | null> => {
  if (!id) return null;
  const response = await api.get(reportUrl(id));

  return response.data;
};

export const createReport = async (data: Partial<Report>): Promise<Report> => {
  const response = await api.post(createReportUrl(), data);

  return response.data;
};

export const createEventReport = async (
  eventId: number | undefined,
  data: Partial<Report>
): Promise<Report> => {
  const response = await api.post(createEventReportUrl(eventId), data);

  return response.data;
};

export const createReportAttachement = async (
  data: ReportAttachmentInput
): Promise<ReportAttachmentUpload> => {
  const { reportId, attachmentKey } = data;
  const response = await api.put(createReportAttachmentUrl(reportId), {
    attachmentKey,
  });

  return response.data;
};

export const uploadReportAttachement = async (data: any): Promise<any> => {
  const response = await api.put(data.uploadUrl, data.image);
  return response.data;
};

export const useReports = (data: ReportsSearch, params: PaginatedApiParams) => {
  return useQuery(
    `reports#${reportsUrl(params)}?${Object.values(data)
      .map((val: string | number | boolean | null) => val)
      .join(',')}`,
    () => getReports(data, params)
  );
};

export const useReport = (id: number | undefined) => {
  return useQuery(`report#id=${id}`, () => getReport(id));
};

export const useReportsTypes = (locale: string) => {
  return useQuery(`reports#types?locale=${locale}`, () =>
    getReportsTypes(locale)
  );
};

export const useCreateReport = () => {
  return useMutation((data: Partial<Report>) => createReport(data));
};

export const useCreateEventReport = (eId: number | undefined) => {
  //refactor this
  return useMutation(
    ({ data, eventId }: { data: Partial<Report>; eventId?: number }) =>
      createEventReport(eId || eventId, data)
  );
};

export const useCreateReportAttachement = () => {
  return useMutation((data: ReportAttachmentInput) =>
    createReportAttachement(data)
  );
};

export const useUploadReportAttachement = () => {
  return useMutation((data: any) => uploadReportAttachement(data));
};
