import { useState } from 'react';

import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import {
  AlternateEmail,
  LockPerson,
  VisibilityOff,
  Visibility,
} from '@mui/icons-material';

import { Trans, useTranslation } from 'react-i18next';
import {
  auth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from '../../config/firebase';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthError, UserCredential } from 'firebase/auth';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { schema } from './validation';
import { LoadingButtonStyled } from './styles';
import { LOCAL_STORAGE_AUTH_TOKEN_KEY } from 'src/components/ProtectedRoute';

type LoginInputs = {
  email: string;
  password: string;
};

export const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [queryParams] = useSearchParams();
  const returnTo = queryParams.get('returnTo');

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resetPasswordForm, setResetPasswordForm] = useState(false);
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<LoginInputs>({
    resolver: yupResolver(schema(t)),
  });

  const onSubmit = (values: LoginInputs) => {
    const { email, password } = values;

    setIsLoading(true);

    if (resetPasswordForm) {
      sendPasswordResetEmail(auth, email)
        .then(() => {
          setPasswordResetSuccess(true);
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      signInWithEmailAndPassword(auth, email, password)
        .then(() => {
          navigate(returnTo || '/map');
        })
        .catch((err: AuthError) => {
          setError(true);
          reset(values);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <div
      className="bg-grey-500 h-screen flex flex-col items-center justify-between"
      data-testid="login-page"
    >
      <div className="flex flex-col items-center justify-center h-full">
        <img className="mb-20" src="/logo.png" alt="logo" />
        <img className="mb-5" src="/appTitle.png" alt="logo" />
        <p className="mb-10">{t('login.title')}</p>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col w-full"
        >
          <label className="mb-3" htmlFor="email">
            {t('login.email')}
          </label>
          <TextField
            className="!border-2"
            placeholder="youremail@gmail.com"
            id="email"
            type="text"
            data-testid="email-input"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AlternateEmail fontSize="inherit" />
                </InputAdornment>
              ),
            }}
            error={!!errors?.email?.message}
            helperText={errors?.email?.message}
            {...register('email')}
          />
          {!resetPasswordForm ? (
            <>
              <label className="mt-5 mb-3" htmlFor="password">
                {t('login.password')}
              </label>
              <TextField
                id="password"
                data-testid="password-input"
                className="mb-5"
                placeholder="*******"
                variant="outlined"
                required={true}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockPerson fontSize="inherit" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        className="rtl:p-0"
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(true)}
                        onMouseDown={() => setShowPassword(false)}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOff fontSize="inherit" />
                        ) : (
                          <Visibility fontSize="inherit" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!!errors?.password?.message}
                helperText={errors?.password?.message}
                {...register('password')}
              />
            </>
          ) : null}
          {error && !isDirty ? (
            <span
              data-testid="error-container"
              className="bg-red-300 text-red-700 p-2 my-2 rounded-md"
            >
              {resetPasswordForm
                ? t('login.errorMessages.reset')
                : t('login.errorMessages.login')}
            </span>
          ) : null}
          {passwordResetSuccess ? (
            <span
              data-testid="error-container"
              className="bg-green-300 text-green-700 p-2 my-2 rounded-md flex-wrap"
            >
              {t('login.resetSuccess')}
            </span>
          ) : null}
          <LoadingButtonStyled
            data-testid="login-button"
            className="!bg-primary p-4 !mt-5 !rounded-md !normal-case "
            variant="contained"
            type="submit"
            disabled={isLoading}
            loading={isLoading}
          >
            {resetPasswordForm ? t('login.resetPassword') : t('login.signIn')}
          </LoadingButtonStyled>
          <Button
            data-testid="forgot-password-button"
            className="!text-grey-700 !mt-5 text-center !normal-case"
            onClick={() => {
              setResetPasswordForm(!resetPasswordForm);
            }}
          >
            {resetPasswordForm
              ? t('login.iHaveAnAccount')
              : t('login.forgotPassword')}
          </Button>
        </form>
      </div>
      <p className="text-grey-700 text-xs mb-20">
        <Trans i18nKey={'login.copyright'}>
          Copyright © <span className="text-primary"> Guardians App. </span> All
          rights reserved.
        </Trans>
      </p>
    </div>
  );
};
