import { useMutation, useQuery, useQueryClient } from 'react-query';
import { api, PaginatedApiResponse, PaginatedApiParams } from 'src/app/api';
import { Report } from './reports';
import { User } from './users';

export type EventsSearch = {
  userId?: string | null;
  eventId?: string | null;
  active?: boolean | null;
  expired?: boolean | null;
};

export type ActiveGuardian = {
  activeGuardianId: number;
  eventId: number;
  tenantId: number;
  userId: number;
  name: string | null;
  imageUrl: string | null;
  community: boolean;
  createdAt: string;
  longitude: number | null;
  latitude: number | null;
}; // or Guardian

export type Event = {
  userId: number;
  eventId: number;
  tenantId: number;
  active: boolean;
  expired: boolean;
  longitude: number;
  latitude: number;
  radius: number;
  conversationSid: string;
  locationUpdatedAt: string;
  community: boolean;
  createdAt: string;
  resolvedAt: string | null;
};

const eventsUrl = (params: PaginatedApiParams) => {
  const { page, size } = params;
  return `/events/search?page=${page}&size=${size}`;
};

const eventUrl = (id: string) => {
  return `/events/${id}`;
};

const communityEventUrl = () => {
  return `/events/community`;
};

const eventConversationUrl = (id: string) => {
  return `/events/${id}/conversation`;
};

const eventDetailsUrl = (id: string) => {
  return `/events/${id}/details`;
};

const eventReportsUrl = (id: number) => {
  return `/events/${id}/reports`;
};

const eventGuardiansUrl = (id: string) => {
  return `/events/${id}/guardians`;
};

const resolveEventUrl = (id: number) => {
  return `/events/${id}/resolve`;
};

export const getEvents = async (
  data: EventsSearch,
  params: PaginatedApiParams
): Promise<PaginatedApiResponse<Event>> => {
  const response = await api.post(eventsUrl(params), data);

  return response.data;
};

export const getEvent = async (
  id: string | undefined
): Promise<Event | undefined> => {
  if (!id) return;
  const response = await api.get(eventUrl(id));

  return response.data;
};

export const getEventReports = async (
  id: number | undefined
): Promise<Report[] | null> => {
  if (!id) return null;
  const response = await api.get(eventReportsUrl(id));

  return response.data;
};

export const getEventGuardians = async (
  id: string | undefined
): Promise<ActiveGuardian[] | null> => {
  if (!id) return null;
  const response = await api.get(eventGuardiansUrl(id));

  return response.data;
};

export const joinEventConversation = async (
  id: string | undefined
): Promise<Event | undefined> => {
  if (!id) return;
  const response = await api.put(eventConversationUrl(id), {});

  return response.data;
};

export const createCommnuityEvent = async (
  data: Partial<Event & { totalDuration: number; message: string }>
): Promise<Event | undefined> => {
  const response = await api.post(communityEventUrl(), data);

  return response.data;
};

export const createEventConversation = async (
  id: string | undefined
): Promise<Event | undefined> => {
  if (!id) return;
  const response = await api.post(eventConversationUrl(id), {});

  return response.data;
};

export const resolveEvent = async (
  id: number | undefined
): Promise<Event | undefined> => {
  if (!id) return;
  const response = await api.put(resolveEventUrl(id), {});

  return response.data;
};

export const useEventReports = (id: number | undefined) => {
  return useQuery(`eventReports#${id}`, () => getEventReports(id));
};

export const useEventGuardians = (id: string | undefined) => {
  return useQuery(`eventGuardians#${id}`, () => getEventGuardians(id));
};

export const useEvent = (id: string | undefined) => {
  return useQuery(`event#${id}`, () => getEvent(id));
};

export const useResolveEvent = (id: number | undefined) => {
  const queryClient = useQueryClient();
  return useMutation(() => resolveEvent(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(`event#${id}`);
    },
  });
};

export const useJoinEventConversation = () => {
  return useMutation((id: string | undefined) => joinEventConversation(id));
};

export const useCreateEventConversation = (eventId: string | undefined) => {
  const queryClient = useQueryClient();
  return useMutation((id: string | undefined) => createEventConversation(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(`event#${eventId}`);
    },
  });
};

export const useCreateCommunityEvent = () => {
  return useMutation(
    (data: Partial<Event & { totalDuration: number; message: string }>) =>
      createCommnuityEvent(data),
    {}
  );
};

export const useEvents = (data: EventsSearch, params: PaginatedApiParams) => {
  return useQuery(
    `events#${eventsUrl(params)}?${Object.values(data)
      .map((val: string | boolean | null) => val)
      .join(',')}`,
    () => getEvents(data, params)
  );
};
