import { NewReleases, OpenInNew } from '@mui/icons-material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTimeAgo from 'react-time-ago';
import { NotificationType } from 'src/components/NotificationWrapper';

type Props = {
  notification: NotificationType;
  locale: string;
};

export const NotificationContent: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { notification, locale } = props;

  return (
    <>
      <NewReleases fontSize="large" className="text-primary" />
      <div className="flex flex-col ml-5 grow">
        <span className="text-secondary/90">{notification?.data?.title}</span>
        {!['EVENT_RESOLVED', 'PROTECTION'].includes(
          notification?.data?.type
        ) ? (
          <span className="font-bold text-primary">
            {t('notification.helpNow')}
            <OpenInNew fontSize="small" className="text-primary mx-2" />
          </span>
        ) : null}
        {notification?.data?.createdAt ? (
          <span className="text-secondary/50">
            <ReactTimeAgo
              date={
                notification.data.createdAt
                  ? new Date(notification.data.createdAt)
                  : new Date()
              }
              locale={locale}
            />
          </span>
        ) : null}
      </div>
    </>
  );
};
