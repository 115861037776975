import { Close, LocalPhoneTwoTone, OpenInNew } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
} from '@mui/material';
import clsx from 'clsx';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dateToLocaleString } from 'src/app/utils';
import { Detail } from 'src/components/Shared/Detail';
import { useTwilioContext } from 'src/components/TwilioContextProvider';
import { Report } from 'src/repos/reports';
import { useCurrentUser } from 'src/repos/users';

type Props = {
  open: boolean;
  onClose: () => void;
  report: Report | undefined;
};

export const ReportDialog: FC<Props> = (props) => {
  const { onClose, open, report } = props;
  const { t } = useTranslation();
  const [selectedImage, setSelectedImage] = useState<string | undefined>();
  const [selectedVideo, setSelectedVideo] = useState<string | undefined>();
  const [selectedImageNumber, setSelectedImageNumber] = useState<
    number | undefined
  >();
  const { startCall } = useTwilioContext();

  const { data: user } = useCurrentUser();

  return (
    <Dialog
      onClose={onClose}
      open={open}
      className="!rounded-lg"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle className="flex flex-row justify-between items-center !p-7 !bg-grey-500 drop-shadow-lg">
        {t('report.title', { id: report?.reportId })}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            className="!bg-primary/10 !text-primary"
          >
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>
      <div className="flex flex-row !bg-grey-500 rounded-xl justify-items-stretch">
        <div className="basis-1/2 pt-7 ltr:pl-7 trl:pr-7 ltr:pr-4 rtl:pl-4 ">
          <div className="bg-white h-96 p-6 overflow-auto">
            <div className="flex flex-row justify-between">
              <Detail
                title={t('events.columns.userName')}
                description={report?.userName}
                className="text-left"
              />
              {report?.userId ? (
                <IconButton
                  onClick={() => {
                    startCall(report?.userId.toString());
                  }}
                >
                  <LocalPhoneTwoTone />
                </IconButton>
              ) : null}
            </div>
            {report?.address ? (
              <div className="flex flex-row justify-start">
                <Detail
                  title={t('report.location')}
                  description={report?.address}
                  className="text-left pt-6"
                />
              </div>
            ) : null}
            {report?.eventId ? (
              <div className="flex flex-row justify-between pt-6">
                <Detail
                  title={t('event.title', {
                    id: report.eventId,
                    date: dateToLocaleString(report.createdAt, user?.locale),
                  })}
                  description={dateToLocaleString(
                    report?.createdAt,
                    user?.locale
                  )}
                  className="text-left"
                />
                <IconButton
                  onClick={() => {
                    window.open(
                      `/events/${report?.eventId.toString()}`,
                      '_blank'
                    );
                  }}
                >
                  <OpenInNew />
                </IconButton>
              </div>
            ) : null}
            {report?.eventId ? (
              <div className="flex flex-row">
                <Detail
                  title={t('report.details')}
                  description={report?.summary}
                  className="text-left py-6"
                />
              </div>
            ) : null}
          </div>
        </div>
        <div className="basis-1/2 pt-7 ltr:pr-7 trl:pl-7 ltr:pl-4 rtl:pr-4">
          <div className="bg-white p-6 h-96 justify-center overflow-auto">
            {/* // TODO: IMAGE LIST from MUI */}
            {selectedImage || selectedVideo ? (
              <div>
                <div className="flex flex-row justify-between items-center">
                  <p>
                    {selectedVideo ? 'Video' : 'Image'} #{selectedImageNumber}
                  </p>
                  <IconButton
                    aria-label="close"
                    onClick={() => {
                      setSelectedImage(undefined);
                      setSelectedVideo(undefined);
                    }}
                  >
                    <Close className="" />
                  </IconButton>
                </div>
                {selectedVideo ? (
                  <video className="h-72 mx-auto rounded-sm" controls>
                    <source src={selectedVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.style.display = 'none';
                    }}
                    style={{ objectFit: 'cover' }}
                    className="h-72 mx-auto rounded-sm"
                    src={selectedImage}
                    alt={'image-full'}
                  />
                )}
              </div>
            ) : (
              <>
                <p className="text-grey-700 mb-1 font-light text-left">
                  {t('report.gallery')}
                </p>
                <div className="flex flex-row flex-wrap">
                  {report?.attachments.map((attachment, i) => (
                    <div
                      className={clsx(
                        i % 3 === 0 ? 'ltr:pr-6 rtl:pl-6' : '',
                        i % 3 === 1 ? 'px-6' : '',
                        i % 3 === 2 ? 'ltr:pl-6 rtl:pr-6' : '',
                        'basis-1/3 py-6'
                      )}
                      key={i}
                    >
                      {attachment.attachmentUrl.includes('.mp4') ? (
                        <video
                          style={{
                            width: 140,
                            height: 140,
                            objectFit: 'cover',
                          }}
                          className={'cursor-pointer rounded-sm'}
                          onClick={() => {
                            setSelectedVideo(attachment.attachmentUrl);
                            setSelectedImageNumber(i + 1);
                          }}
                        >
                          <source
                            src={attachment.attachmentUrl}
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <img
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.style.display = 'none';
                          }}
                          style={{
                            width: 140,
                            height: 140,
                            objectFit: 'cover',
                          }}
                          src={attachment.attachmentUrl}
                          alt={attachment.attachmentKey}
                          className={'cursor-pointer rounded-sm'}
                          onClick={() => {
                            setSelectedImage(attachment.attachmentUrl);
                            setSelectedImageNumber(i + 1);
                          }}
                        />
                      )}
                    </div>
                  ))}
                  {report?.attachments.length === 0 ? (
                    <p>{t('report.noAttachements')}</p>
                  ) : null}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <DialogActions className="!p-7 flex flex-row !bg-grey-500">
        <Button
          className="!bg-primary/10 !text-primary !py-2 !px-7 text-center !normal-case"
          onClick={() => {
            //call.accept();
          }}
        >
          {t('event.end')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
