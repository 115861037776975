import { TFunction } from 'i18next';
import { object, string } from 'yup';

export const schema = (t: TFunction) => {
  const notAValidEmail = t('login.errorMessages.notAValidEmail');
  const required = t('login.errorMessages.fieldIsRequired');

  return object().shape({
    email: string().email(notAValidEmail).required(required),
    password: string(),
  });
};
