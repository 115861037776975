import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import usersReducer from '../pages/Users/slice';
import eventsReducer from '../pages/Events/slice';
import reportsReducer from '../pages/Reports/slice';

export const store = configureStore({
  reducer: {
    users: usersReducer,
    events: eventsReducer,
    reports: reportsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
