import { Call, Device } from '@twilio/voice-sdk';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTwilioToken } from 'src/repos/twilio';
import {
  Client as ConversationsClient,
  Conversation,
} from '@twilio/conversations';
import { CallDialog } from '../CallDialog';
import { Chat } from '../Chat';

type StartChatProps = {
  id: string;
  conversationSid?: string;
  chatRoomName?: string;
};

type TwilioContextState = {
  device: Device | null;
  chatClient: ConversationsClient | null;
  conversations: Conversation[] | null;
  audioContext: AudioContext | null;
  startCall: (to: string) => void;
  startChat: (props: StartChatProps) => void;
};

const TwilioContext = createContext<TwilioContextState | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export const TwilioContextProvider = (props: Props) => {
  const { children } = props;
  const { data: token, refetch: refetchToken } = useTwilioToken();

  const [voiceDevice, setVoiceDevice] = useState<Device | null>(null);
  const [chatClient, setChatClient] = useState<ConversationsClient | null>(
    null
  );
  const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
  const [conversations, setConversations] = useState<Conversation[]>([]);

  const [currentCall, setCurrentCall] = useState<Call | null>(null);
  const [currentCallPartnerId, setCurrentCallPartnerId] = useState('');

  const [currentConversation, setCurrentConversation] =
    useState<Conversation | null>(null);
  const [currentConversationPartnerId, setCurrentConversationPartnerId] =
    useState('');

  const [chatRoomName, setChatRoomName] = useState<string | undefined>();

  // test later this
  // useEffect(() => {
  //   if (voiceDevice && token?.accessToken) {
  //     voiceDevice.updateToken(token?.accessToken);
  //   }
  //   if (chatClient && token?.accessToken) {
  //     chatClient.updateToken(token?.accessToken);
  //   }
  // }, [token]);

  useEffect(() => {
    if (!token) return;
    if (!voiceDevice) {
      const audioContext = new AudioContext();
      const device = new Device(token.accessToken, {
        // logLevel: 0
      });
      device.on('tokenWillExpire', () => {
        refetchToken();
      });

      device.register();

      // device.on('connect', (connection) => {
      //   console.log('Connect event', connection);
      // });
      // device.on('disconnect', () => {
      //   console.log('Connect event');
      // });
      device.on('incoming', (call: Call) => {
        setCurrentCall(call);
        setCurrentCallPartnerId(call.parameters.From.replace('client:', ''));
      });
      // device.on('cancel', () => {
      //   console.log('reject');
      // });
      // device.on('reject', () => {
      //   console.log('cancel');
      // });

      setVoiceDevice(device);
      setAudioContext(audioContext);
    }
    if (!chatClient) {
      const conversationsClient = new ConversationsClient(token.accessToken, {
        //logLevel: 'debug',
      });
      conversationsClient.on('tokenAboutToExpire', () => {
        refetchToken();
      });
      setChatClient(conversationsClient);
      conversationsClient.on('connectionStateChanged', (state: string) => {
        // console.log(`Connection state: ${state}`);
        // if (state === 'connecting') console.log('chat connecting ');
        // if (state === 'connected') {
        //   console.log('chat connected');
        // }
        // if (state === 'disconnecting') console.log('chat disconnecting');
        // if (state === 'disconnected') console.log('chat disconnected');
        // if (state === 'denied') console.log('chat Denied');
      });
      conversationsClient.on('conversationJoined', (conversation) => {
        setConversations([...conversations, conversation]);
      });
      conversationsClient.on('conversationLeft', (thisConversation) => {
        setConversations([
          ...conversations.filter((it) => it !== thisConversation),
        ]);
      });
      conversationsClient.on('messageAdded', (message) => {
        if (message.conversation) {
          setCurrentConversation(message.conversation);
        }
        if (message?.conversation?.createdBy === 'system') {
          setChatRoomName(message?.conversation?.friendlyName || 'Chat room');
        } else {
          if (message.author) {
            setCurrentConversationPartnerId(message.author);
          }
        }
      });
    }
  }, [token]);

  const startCall = (to: string) => {
    if (voiceDevice) {
      setCurrentCallPartnerId(to === 'helpdesk' ? '15' : to);
      voiceDevice
        .connect({
          params: {
            To: to,
          },
        })
        .then((call) => {
          setCurrentCall(call);
        });
    }
  };

  const computeConversationUniqueName = (to: string, from: string) =>
    to < from ? `chat-${to}-${from}` : `chat-${from}-${to}`;

  const startChat = async (props: StartChatProps) => {
    if (chatClient) {
      const { id, conversationSid, chatRoomName } = props;
      if (conversationSid) {
        setChatRoomName(chatRoomName);
        setCurrentConversationPartnerId('');
        await chatClient
          .getConversationBySid(conversationSid)
          .then((conversation) => {
            setCurrentConversation(conversation);
          });
        return;
      }
      const uniqueName = computeConversationUniqueName(
        id,
        token?.identity || '0'
      );
      //console.log('uniqueName:', uniqueName);
      setCurrentConversationPartnerId(id);
      chatClient
        ?.getConversationByUniqueName(uniqueName)
        .then((conversation) => {
          //console.log('found by uniqueName:', uniqueName, conversation);
          setCurrentConversation(conversation);
        })
        .catch(() => {
          //console.log('creating by uniqueName:', uniqueName);
          chatClient
            ?.createConversation({
              uniqueName: uniqueName,
            })
            .then((conversation) => {
              //console.log('created by uniqueName:', uniqueName);
              conversation
                .join()
                .then((conversation) => {
                  conversation.add(id);
                })
                .catch((err) => {
                  //console.log('Big error boy', err);
                });

              setCurrentConversation(conversation);
            })
            .catch((err) => {
              //console.log('BIG ERROR BOY', err);
            });
        });
    }
  };

  return (
    <TwilioContext.Provider
      value={{
        device: voiceDevice,
        chatClient,
        audioContext,
        conversations,
        startCall,
        startChat,
      }}
    >
      {children}
      {currentCall ? (
        <CallDialog
          call={currentCall}
          open={!!currentCall}
          onClose={() => {
            setCurrentCall(null);
            setCurrentCallPartnerId('');
          }}
          callerId={currentCallPartnerId}
        />
      ) : null}
      {currentConversation ? (
        <Chat
          conversation={currentConversation}
          onClose={async () => {
            setCurrentConversation(null);
            setCurrentConversationPartnerId('');
            setChatRoomName(undefined);
          }}
          open={!!currentConversation}
          partnerUserId={currentConversationPartnerId}
          chatRoom={chatRoomName}
        />
      ) : null}
    </TwilioContext.Provider>
  );
};

export const useTwilioContext = () => {
  const context = useContext(TwilioContext);
  if (context === undefined || context === null) {
    throw new Error(
      `useTwilioContext must be used within an TwilioContextProvider`
    );
  }
  return context;
};
