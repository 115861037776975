import { useQuery } from 'react-query';
import { api } from 'src/app/api';

export type Avatar = {
  key?: string;
  url?: string | null;
};

const url = () => {
  return `/avatars`;
};

export const getAvatars = async (): Promise<Avatar[]> => {
  const response = await api.get(url());

  return response.data;
};

export const useAvatars = () => {
  return useQuery(`#avatars`, () => getAvatars());
};
