import clsx from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { dateToLocaleString } from 'src/app/utils';
import { Detail } from 'src/components/Shared/Detail';
import { UserActionBar } from 'src/components/Shared/UserActionBar';
import { Report } from 'src/repos/reports';
import { useCurrentUser, User } from 'src/repos/users';

type Props = {
  report: Report;
  className?: string;
  onClick: () => void;
  showChat?: boolean;
};

export const ReportComponent: FC<Props> = (props) => {
  const { report, className, onClick, showChat = true } = props;
  const { t } = useTranslation();
  const { data: user } = useCurrentUser();

  return (
    <div
      className={clsx(
        className,
        'flex flex-col bg-grey-500 border border-grey-700/30 rounded-lg'
      )}
      key={report.reportId}
    >
      <div
        className="flex flex-row mb-8 flex-wrap cursor-pointer"
        onClick={onClick}
      >
        <Detail
          title={t('report.location')}
          description={report?.address}
          className="basis-1/2 text-left p-6"
        />
        <Detail
          title={t('report.posted')}
          description={dateToLocaleString(report.createdAt, user?.locale)}
          className="basis-1/2 text-left p-6"
        />
        <Detail
          title={t('report.what')}
          description={report.type}
          className="text-left px-6 pb-6 basis-full"
        />
        <Detail
          title={t('report.details')}
          description={report.summary}
          className="text-left px-6 pb-6 basis-full"
        />
        {report.attachments && report.attachments.length ? (
          <div className="p-6 basis-full">
            <p className="text-grey-700 mb-1 font-light text-left">
              {t('report.attachements')}
            </p>
            <div className="flex flex-row flex-wrap">
              {report.attachments.map((attachment, i) => (
                <div
                  className={clsx(
                    i % 3 === 2 ? 'ltr:pl-6 rtl:pr-6' : '',
                    i % 3 === 1 ? 'px-6' : '',
                    i % 3 === 0 ? 'ltr:pr-6 rtl:pl-6' : '',
                    'basis-1/3 py-6'
                  )}
                  key={i}
                >
                  {attachment.attachmentUrl.includes('.mp4') ? (
                    <video
                      style={{ width: 100, height: 100, objectFit: 'cover' }}
                    >
                      <source src={attachment.attachmentUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <img
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.style.display = 'none';
                      }}
                      style={{ width: 100, height: 100, objectFit: 'cover' }}
                      src={attachment.attachmentUrl}
                      alt={attachment.attachmentKey}
                      className="rounded-sm"
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
      {report && report.userName ? (
        <UserActionBar
          className="px-6 bg-grey-600"
          user={
            {
              name: report.userName,
              userId: report.userId,
              imageUrl: report.userImageUrl,
            } as User
          }
        />
      ) : null}
    </div>
  );
};
