import { LocalPhoneTwoTone, MessageTwoTone } from '@mui/icons-material';
import { Avatar, IconButton } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'src/app/store';
import { Table } from 'src/components/Table';
import { SelectFilter, TextSearchFilter } from 'src/components/Table/utils';
import { useTwilioContext } from 'src/components/TwilioContextProvider';
import { User } from 'src/repos/users';
import {
  fetchUsers,
  selectPageSize,
  selectUsers,
  selectPageIndex,
  selectPagesCount,
  setFilter,
  selectFilters,
  removeFilter,
} from './slice';

export const UsersPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const users = useSelector(selectUsers);
  const { audioContext, startCall, startChat } = useTwilioContext();

  const { t } = useTranslation();

  const serverPageSize = useSelector(selectPageSize);
  const serverPageIndex = useSelector(selectPageIndex);
  const serverPagesCount = useSelector(selectPagesCount);

  const filters = useSelector(selectFilters);

  const UserCell = (props: { row: { original: User } }) => {
    const {
      row: { original },
    } = props;

    return (
      <div className="flex flex-row">
        <Avatar
          src={original.imageUrl || ''}
          alt="user-avatar"
          sx={{ width: 30, height: 30 }}
        ></Avatar>
        <span className="ltr:ml-4 rtl:mr-4 font-semibold truncate">
          {original.name}
        </span>
      </div>
    );
  };

  const CommandControlEnabled = (
    props: { row: { original: User } },
    column: string
  ) => {
    const {
      row: { original },
    } = props;

    const Tag = ({ className, text }: { className: string; text: string }) => (
      <div
        className={clsx(
          className,
          'py-2 rounded-[2.625rem] text-center truncate px-2'
        )}
      >
        <span className="truncate">{text} </span>
      </div>
    );

    return original.commandControlEnabled ? (
      <Tag
        className="bg-green-700/[.15] text-green-700"
        text={t('general.enabled')}
      />
    ) : (
      <Tag
        className="bg-grey-100/[.4] text-grey-600"
        text={t('general.disabled')}
      />
    );
  };

  const columns = [
    {
      Header: t('table.id'),
      accessor: 'userId',
      Filter: (
        <TextSearchFilter
          setFilter={setFilter}
          removeFilter={removeFilter}
          name="userId"
          placeholder={t('table.id') as string}
        />
      ),
    },
    {
      Header: t('users.columns.name'),
      accessor: 'name',
      Filter: (
        <TextSearchFilter
          setFilter={setFilter}
          removeFilter={removeFilter}
          name="name"
          placeholder={t('users.columns.name') as string}
        />
      ),

      Cell: UserCell,
    },
    {
      Header: t('users.columns.email'),
      accessor: 'email',
      Filter: (
        <TextSearchFilter
          setFilter={setFilter}
          removeFilter={removeFilter}
          name="email"
          placeholder={t('users.columns.email') as string}
        />
      ),
    },
    {
      Header: t('users.columns.phone'),
      accessor: 'phoneNumber',
      Filter: (
        <TextSearchFilter
          setFilter={setFilter}
          removeFilter={removeFilter}
          name="phoneNumber"
          placeholder={t('users.columns.phone') as string}
        />
      ),
    },
    {
      id: 'guardians',
      Header: t('users.columns.guardians'),
      accessor: '',
      Cell: (props: { row: { original: User } }) => {
        const {
          row: { original },
        } = props;

        return (
          <span>
            {t('users.data.guardiansCount', { count: original.guardianCount })}
          </span>
        );
      },
    },
    {
      Header: t('users.columns.address'),
      accessor: 'address',
      Filter: (
        <TextSearchFilter
          setFilter={setFilter}
          removeFilter={removeFilter}
          name="address"
          placeholder={t('users.columns.address') as string}
        />
      ),
    },
    {
      Header: t('users.columns.gender'),
      accessor: 'gender',
      Filter: (
        <SelectFilter
          setFilter={setFilter}
          removeFilter={removeFilter}
          name="gender"
        />
      ),
    },
    {
      Header: t('users.columns.birthday'),
      accessor: 'birthday',
    },
    {
      Header: t('users.columns.commandControl'),
      accessor: 'commandControlEnabled',
      Cell: CommandControlEnabled,
    },
    {
      Header: t('users.columns.actions'),
      accessor: 'actions',
      id: 'actions',
      Cell: (props: { row: { original: User } }) => {
        const {
          row: { original },
        } = props;

        return (
          <>
            <IconButton
              onClick={async () => {
                startChat({ id: original.userId.toString() });
              }}
            >
              <MessageTwoTone />
            </IconButton>
            <IconButton
              onClick={async () => {
                audioContext?.resume();
                startCall(original.userId.toString());
              }}
            >
              <LocalPhoneTwoTone />
            </IconButton>
          </>
        );
      },
    },
  ];
  const data = users?.content || [];

  return (
    <>
      <div className="container-lg mx-auto flex grow">
        <div className="bg-white rounded-lg p-8 m-10 flex grow">
          <Table
            data={data}
            columns={columns}
            serverPageIndex={serverPageIndex || 0}
            serverPageSize={serverPageSize || 10}
            serverPagesCount={serverPagesCount || 1}
            fetch={(params) => {
              dispatch(fetchUsers(params));
            }}
            filters={filters}
          />
        </div>
      </div>
    </>
  );
};
