import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { dateToLocaleString } from 'src/app/utils';
import { Event } from 'src/components/Event';
import { Map } from 'src/components/Map';
import { useNotificationsContext } from 'src/components/NotificationWrapper';
import { useUserContext } from 'src/components/ProtectedRoute';
import { Reports } from 'src/components/Reports';
import { useEvent, useEventGuardians, useEventReports } from 'src/repos/events';

export const EventPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const { data: event, refetch: refetchEvent } = useEvent(id);
  const { data: guardians, refetch: refetchGuardians } = useEventGuardians(
    event?.eventId.toString()
  );
  const { data: reports, refetch: refetchReports } = useEventReports(
    event?.eventId
  );
  const { user } = useUserContext();
  const { notifications } = useNotificationsContext();

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    let interval: NodeJS.Timer | undefined;
    if (event?.locationUpdatedAt) {
      const startIntervalTime =
        new Date().getTime() -
        (new Date(event.locationUpdatedAt).getTime() || 0);
      timeout = setTimeout(
        () => {
          refetchEvent();
          interval = setInterval(() => {
            refetchEvent();
          }, 60000);
        },
        startIntervalTime > 60000 ? 60000 : startIntervalTime
      );
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      } else if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [event]);

  useEffect(() => {
    if (notifications?.length > 0) {
      const lastNoficiation = notifications.at(-1);
      //if (id !== lastNoficiation?.data?.id) return;
      switch (lastNoficiation?.data?.type) {
        case 'EVENT':
          refetchEvent();
          return;
        case 'REPORT':
          refetchReports();
          return;
        case 'EVENT_UPDATED':
          refetchEvent();
          refetchGuardians();
          return;
      }
    }
  }, [notifications]);

  return (
    <>
      <div className="text-secondary italic text-center text-2xl">
        {t('event.title', {
          id: event?.eventId,
          date: dateToLocaleString(event?.createdAt, user?.locale),
        })}
      </div>
      <div className="bg-grey-500 flex flex-row justify-start p-10 flex-wrap">
        <Event
          event={event}
          className="md:basis-1/2 sm:basis-full xs:basis-full xl:basis-1/3 ltr:md:pr-5 rtl:md:pl-5"
        />
        <Map
          guardians={guardians}
          event={event}
          className="xl:basis-1/3 max-xl:order-last max-xl:mt-10 xl:px-5"
        />
        <Reports
          reports={reports}
          event={event}
          className="md:basis-1/2 sm:basis-full xs:basis-full xl:basis-1/3 pl-5 max-md:mt-10"
        />
      </div>
    </>
  );
};
