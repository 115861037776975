import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import clsx from 'clsx';
import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFilters, usePagination, useTable } from 'react-table';
import { PaginatedApiParams } from 'src/app/api';
import { Event } from 'src/repos/events';
import { Report } from 'src/repos/reports';
import { User } from 'src/repos/users';

type TableRow = Record<string, unknown>;

type Columns = {
  Header: string;
  accessor: string;
}[];

type Props = {
  data: TableRow[];
  columns: Columns;
  serverPageSize: number;
  serverPageIndex: number;
  serverPagesCount: number;
  fetch: (params: {
    filters: Record<string, unknown>;
    params: PaginatedApiParams;
  }) => void;
  filters: Record<string, unknown>;
  onRowClick?: (row: Event | User | Report) => void;
};

export const Table: FC<Props> = (props) => {
  const {
    data,
    columns,
    serverPageSize,
    serverPageIndex,
    serverPagesCount,
    fetch,
    filters,
    onRowClick,
  } = props;

  const { t } = useTranslation();

  const [page, setPage] = useState(serverPageIndex || 0);
  const [pageSize, setPageSize] = useState(serverPageSize || 10);

  const tableData = useMemo(() => data, [data]);
  const tableColumns = useMemo(() => columns, [columns]);

  useEffect(() => {
    if (serverPageIndex === 0 && page > 0) {
      setPage(0);
      setPageSize(serverPageSize);
    }
  }, [serverPageIndex, serverPageSize, serverPagesCount]);

  useEffect(() => {
    fetch({
      filters,
      params: { page, size: pageSize },
    });
  }, [filters, page, pageSize]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: tableColumns,
        data: tableData,
        manualPagination: true,
        pageCount: serverPagesCount || 1,
        manualFilters: true,
      },
      useFilters,
      usePagination
    );

  return (
    <div className="flex flex-col justify-center h-full max-h-full">
      <table {...getTableProps()} className="max-w-full h-full">
        <thead className="bg-grey-500">
          {headerGroups.map((headerGroup, i) => (
            <Fragment key={i}>
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => {
                  return (
                    <th
                      {...column.getHeaderProps()}
                      className={clsx(
                        i === 0 &&
                          'ltr:rounded-tl-[20px] rtl:rounded-tr-[20px]',
                        i === columns.length - 1 &&
                          'ltr:rounded-tr-[20px] rtl:rounded-tl-[20px]',
                        'px-6 pt-6 align-top text-left',
                        ['actions', 'guardians'].includes(column.id)
                          ? 'min-w-[140px]'
                          : 'min-w-[70px]'
                      )}
                    >
                      {column.render('Header')}
                    </th>
                  );
                })}
              </tr>
              <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column, i) => {
                  return (
                    <th
                      {...column.getHeaderProps()}
                      className={clsx('px-6 pb-6 align-top text-left')}
                      key={`header-${i}`}
                    >
                      <div>
                        {column.Filter ? column.render('Filter') : null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            </Fragment>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                className={clsx(
                  onRowClick ? 'cursor-pointer' : '',
                  'border-b border-b-gray-100 max-h-20 h-20'
                )}
                onClick={() => {
                  onRowClick && onRowClick(row.original as any);
                }}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      className="p-4 truncate max-w-0"
                      {...cell.getCellProps()}
                    >
                      {cell.value !== null ? (
                        <Tooltip title={cell.render('Cell')}>
                          <span>{cell.render('Cell')}</span>
                        </Tooltip>
                      ) : (
                        <Tooltip title={t('general.unknown')}>
                          <p className="text-gray-500 font-light italic truncate">
                            {t('general.unknown')}
                          </p>
                        </Tooltip>
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          {page + 1 >= serverPagesCount ? <tr></tr> : null}
        </tbody>
      </table>
      <div className="flex flex-row justify-between bg-grey-500">
        <div className="p-6">
          <span className="mr-2">{t('table.page')}</span>
          <input
            type="number"
            max={serverPagesCount}
            min={1}
            defaultValue={page + 1}
            onChange={(e) => {
              const newPage = Number(e.target.value) - 1;
              if (newPage >= 0 && newPage <= serverPagesCount) {
                setPage(newPage);
              }
            }}
            className="w-20 px-2"
          />
        </div>
        <div className="items-center flex">
          <Button
            //startIcon={<ArrowBackIos />}
            disabled={page <= 0}
            onClick={() => {
              setPage(page - 1);
            }}
          >
            {t('table.prev')}
          </Button>
          <span className="mx-10">{page + 1}</span>
          <Button
            //endIcon={<ArrowForwardIos />}
            disabled={page + 1 >= serverPagesCount}
            onClick={() => {
              setPage(page + 1);
            }}
          >
            {t('table.next')}
          </Button>
        </div>
        <div className="px-12 py-6">
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              setPage(0);
            }}
          >
            {[2, 4, 6, 8, 10].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {t('table.show', { page: pageSize })}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};
