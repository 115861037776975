import { TFunction } from 'i18next';
import { object, string, number } from 'yup';

export const schema = (t: TFunction) => {
  const required = t('login.errorMessages.fieldIsRequired');

  return object().shape({
    location: object().required(required),
    radius: number(),
    type: string().required(required),
    message: string().required(required),
  });
};
