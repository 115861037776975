import './index.css';
import 'react-toastify/dist/ReactToastify.css';

import createCache from '@emotion/cache';
import type { EmotionCache } from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { Home } from './pages/Home';
import { Login } from './pages/Login';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import { ProtectedRoute } from './components/ProtectedRoute';
import { EventPage } from './pages/Event';
import { Layout } from './components/Layout';
import { MapPage } from './pages/Map';
import { UsersPage } from './pages/Users';
import { EventsPage } from './pages/Events';
import { ReportsPage } from './pages/Reports';
import { SettingsPage } from './pages/Settings';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import { hotjar } from 'react-hotjar';

import TimeAgo from 'javascript-time-ago';

import en from 'javascript-time-ago/locale/en.json';
import he from 'javascript-time-ago/locale/he.json';
import { Suspense } from 'react';
import { Loader } from './components/ProtectedRoute/Loader';

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(he);

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 3,
      retryDelay: 1000,
    },
  },
});

const router = createBrowserRouter([
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/events/:id',
    element: (
      <ProtectedRoute>
        <Layout>
          <EventPage />
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: '/map',
    element: (
      <ProtectedRoute>
        <Layout>
          <MapPage />
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: '/users',
    element: (
      <ProtectedRoute>
        <Layout>
          <UsersPage />
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: '/events',
    element: (
      <ProtectedRoute>
        <Layout>
          <EventsPage />
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: '/reports',
    element: (
      <ProtectedRoute>
        <Layout>
          <ReportsPage />
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: '/settings',
    element: (
      <ProtectedRoute>
        <Layout>
          <SettingsPage />
        </Layout>
      </ProtectedRoute>
    ),
  },
  {
    path: '*',
    element: <Navigate to="/map" replace />,
  },
]);
export const createEmotionCache = (): EmotionCache => {
  return createCache({ key: 'css' });
};
const emotionCache = createEmotionCache();

function App() {
  const hotjarHjid = process.env.REACT_APP_HOTJAR_HJID;
  const hotjarHjsv = process.env.REACT_APP_HOTJAR_HJSV;
  if (hotjarHjid && hotjarHjsv) {
    hotjar.initialize(Number(hotjarHjid), Number(hotjarHjsv));
  }

  return (
    <Suspense fallback={<Loader />}>
      <CacheProvider value={emotionCache}>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
          <ToastContainer />
        </QueryClientProvider>
      </CacheProvider>
    </Suspense>
  );
}

export default App;
