import { ReactElement, FC } from 'react';
import { Navbar } from '../Navbar';
import { SideNav } from '../SideNav';

type Props = {
  children: ReactElement;
};

export const Layout: FC<Props> = (props) => {
  const { children } = props;

  return (
    <div className="flex flex-row grow bg-grey-500 h-full min-h-full">
      <SideNav />
      <div className="flex flex-col grow overflow-auto">
        <Navbar />
        <div className="pt-[90px] flex"></div>
        {children}
      </div>
    </div>
  );
};
