import { createContext, FC, ReactNode, useContext, useEffect } from 'react';

import {
  auth,
  getMessagingToken,
  onAuthStateChanged,
} from '../../config/firebase';
import { User as FirebaseUser } from 'firebase/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useCurrentUser,
  User,
  useRegisterUser,
  useUnregisterUser,
} from 'src/repos/users';
import { TwilioContextProvider } from '../TwilioContextProvider';
import { Loader } from './Loader';
import { NotificationWrapper } from '../NotificationWrapper';

type Props = {
  children: ReactNode;
};
export const LOCAL_STORAGE_AUTH_TOKEN_KEY = 'AUTH_TOKEN';
export const LOCAL_STORAGE_FIREBASE_MESSAGE_TOKEN_KEY =
  'FIREBASE_MESSAGE_TOKEN';

type UserContextState = {
  user?: User;
};

const UserContext = createContext<UserContextState | undefined>(undefined);

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (context === undefined || context === null) {
    throw new Error(
      `useUserContext must be used within an UserContextProvider`
    );
  }
  return context;
};

export const ProtectedRoute: FC<Props> = (props) => {
  const { children } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { data: user, isLoading } = useCurrentUser();
  const { mutate: registerUserMessaging } = useRegisterUser();
  const { mutate: unregisterUserMessaging } = useUnregisterUser();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (user?.locale) {
      i18n.changeLanguage(user.locale);
      document
        .getElementsByTagName('html')[0]
        .setAttribute('dir', user.locale === 'he' ? 'rtl' : 'ltr');
    } else {
      i18n.changeLanguage('en');
      document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
    }
  }, [i18n, user]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      auth,
      async (userAuth: FirebaseUser | null) => {
        if (userAuth) {
          const firbaseMessagingToken =
            window?.localStorage?.getItem(
              LOCAL_STORAGE_FIREBASE_MESSAGE_TOKEN_KEY
            ) || undefined;
          if (!firbaseMessagingToken) {
            const messaginToken = await getMessagingToken();

            if (messaginToken) {
              registerUserMessaging({ fcmToken: messaginToken });
              window?.localStorage?.setItem(
                LOCAL_STORAGE_FIREBASE_MESSAGE_TOKEN_KEY,
                messaginToken
              );
            }
          }
          const token = await userAuth.getIdToken();
          window?.localStorage?.setItem(LOCAL_STORAGE_AUTH_TOKEN_KEY, token);
        } else {
          const firbaseMessagingToken =
            window?.localStorage?.getItem(
              LOCAL_STORAGE_FIREBASE_MESSAGE_TOKEN_KEY
            ) || undefined;

          if (firbaseMessagingToken) {
            unregisterUserMessaging(
              { fcmToken: firbaseMessagingToken },
              {
                onSettled: () => {
                  window?.localStorage?.removeItem(
                    LOCAL_STORAGE_AUTH_TOKEN_KEY
                  );
                  window?.localStorage?.removeItem(
                    LOCAL_STORAGE_FIREBASE_MESSAGE_TOKEN_KEY
                  );
                  navigate(`/login?returnTo=${pathname}`);
                },
              }
            );
          } else {
            window?.localStorage?.removeItem(LOCAL_STORAGE_AUTH_TOKEN_KEY);
            navigate(`/login?returnTo=${pathname}`);
          }
        }
      }
    );

    return unsubscribe;
  }, []);

  if (!user || isLoading) {
    return <Loader />;
  }

  return (
    <UserContext.Provider value={{ user }}>
      <NotificationWrapper>
        <TwilioContextProvider>{children}</TwilioContextProvider>
      </NotificationWrapper>
    </UserContext.Provider>
  );
};
