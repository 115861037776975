import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getNotificationRedirectUrl,
  NotificationType,
} from 'src/components/NotificationWrapper';
import { NotificationContent } from '../Content';

type Props = {
  notification: NotificationType;
  locale: string;
};

export const NotificationWrapper: FC<Props> = (props) => {
  const { notification, locale } = props;

  const navigate = useNavigate();
  return (
    <div
      className="pb-5 flex flex-row items-center cursor-pointer"
      onClick={() => {
        const url = getNotificationRedirectUrl(notification);

        url && navigate(url);
      }}
    >
      <NotificationContent notification={notification} locale={locale} />
    </div>
  );
};
