import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PaginatedApiResponse, PaginatedApiParams } from 'src/app/api';
import { RootState } from 'src/app/store';
import { getUsers, User, UsersSearch } from 'src/repos/users';

export type UsersState = {
  users: PaginatedApiResponse<User> | null;
  status?: 'idle' | 'loading' | 'succeeded' | 'failed';
  error?: string | null;
  filters: UsersSearch;
};

export type FetchUsersParams = {
  filters: UsersSearch;
  params: PaginatedApiParams;
};

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    users: null,
    status: 'idle',
    error: null,
    filters: {},
  } as UsersState,
  reducers: {
    setFilter: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    removeFilter: (state, action) => {
      delete state.filters[action.payload as keyof UsersSearch];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload;
      })
      .addCase(fetchUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const fetchUsers = createAsyncThunk(
  'users/fetchUsers',
  async (searchParams: FetchUsersParams, { getState }) => {
    const { filters, params } = searchParams;

    return await getUsers(filters, params);
  }
);

export const { setFilter, removeFilter } = usersSlice.actions;

// selectors
export const selectUsers = (state: RootState) => state.users.users;
export const selectPageSize = (state: RootState) =>
  state.users.users?.pageable.pageSize;
export const selectPageIndex = (state: RootState) =>
  state.users.users?.pageable.pageNumber;
export const selectPagesCount = (state: RootState) =>
  state.users.users?.totalPages;
export const fetchUsersStatus = (state: RootState) => state.users.status;
export const selectFilters = (state: RootState) => state.users.filters;

export default usersSlice.reducer;
