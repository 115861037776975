import { yupResolver } from '@hookform/resolvers/yup';
import { ArrowBackIos, Close, Send } from '@mui/icons-material';
import {
  Avatar,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputBase,
  Paper,
} from '@mui/material';
import { Conversation, Message } from '@twilio/conversations';
import { t } from 'i18next';
import { useRef, FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTwilioIdentity } from 'src/repos/twilio';
import { useCurrentUser } from 'src/repos/users';
import { MessageComponent } from './components/Message';
import { DialogStyled } from './styles';
import { schema } from './validation';

type Props = {
  open: boolean;
  onClose: () => void;
  partnerUserId: string;
  conversation: Conversation | null;
  chatRoom: string | undefined;
};

type MessageInput = {
  message: string;
};

export const Chat: FC<Props> = (props) => {
  const { onClose, open, partnerUserId, conversation, chatRoom } = props;
  const [messages, setMessages] = useState<Message[]>([]);
  const { data: messagerIdentity } = useTwilioIdentity(
    partnerUserId,
    !chatRoom
  );
  const { data: user } = useCurrentUser();

  const messagesContainer = useRef<HTMLDivElement | null>(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<MessageInput>({
    resolver: yupResolver(schema()),
  });

  const onSubmit = (values: MessageInput) => {
    const { message } = values;
    if (message) {
      conversation?.sendMessage(message).then((newmsg) => {
        reset({ message: '' });
      });
    }
  };

  const loadMessages = () => {
    conversation?.getMessages().then((messages) => {
      const msgs: Message[] = [];
      messages.items.forEach((message) => {
        msgs.push(message);
      });
      setMessages(msgs);
      messagesContainer.current?.scrollTo(
        0,
        messagesContainer.current.scrollHeight + 200
      );
    });
  };

  useEffect(() => {
    if (conversation) {
      loadMessages();

      conversation.on('messageAdded', (message) => {
        loadMessages();
      });
    }
  }, [conversation, messagesContainer.current]);

  return (
    <DialogStyled
      onClose={onClose}
      open={open}
      className="!rounded-xl"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className="flex flex-row justify-between items-center !p-6 border-b border-grey-100/70">
        <div className="flex flex-row">
          <IconButton aria-label="close" onClick={onClose}>
            <ArrowBackIos />
          </IconButton>
          <Avatar
            alt={messagerIdentity?.name || ''}
            src={messagerIdentity?.imageUrl || ''}
            sx={{ width: 60, height: 60 }}
          />
          <div className="ltr:ml-6 rtl:mr-6 font-medium text-secondary">
            {messagerIdentity?.name || chatRoom ? (
              <span className="text-base">
                {messagerIdentity?.name || chatRoom}
              </span>
            ) : (
              <span className="text-base italic text-grey-600">
                {t('general.unknown')}
              </span>
            )}
          </div>
        </div>
        {onClose ? (
          <IconButton aria-label="close" onClick={onClose}>
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent
        ref={messagesContainer}
        className="flex flex-col max-h-96 overflow-y-auto px-8 !pb-8 !pt-5"
        style={{ minHeight: '24rem' }}
      >
        {messages.map((message, i) => (
          <MessageComponent
            key={i}
            message={message}
            messages={messages}
            index={i}
            curentUserId={user?.userId.toString()}
          />
        ))}
      </DialogContent>
      <DialogActions className="flex flex-row !p-6">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex-grow flex flex-row items-center"
        >
          <Paper className="p-4 px-8 ltr:mr-5 rtl:ml-5 !rounded-[90px] border flex-grow">
            <InputBase
              className="flex flex-grow"
              placeholder="Send a message"
              {...register('message')}
            />
          </Paper>
          <IconButton
            aria-label="close"
            className="!bg-primary !text-white !p-4 rounded"
            type="submit"
          >
            <Send />
          </IconButton>
        </form>
      </DialogActions>
    </DialogStyled>
  );
};
