import { Avatar, Button, MenuItem, Switch, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCurrentUser, useUpdateCurrentUser } from 'src/repos/users';
import 'react-phone-number-input/style.css';

import { PhoneInputStyled, SelectStyled } from './styles';
import { auth } from 'src/config/firebase';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './validation';
import { dateWithoutTime } from 'src/app/utils';
import { AvatarDialog } from 'src/components/AvatarDialog';
import { toast } from 'react-toastify';
import { Avatar as AvatarType } from 'src/repos/avatar';

type UserInput = {
  name: string | null;
  email: string;
  phoneNumber: string | null;
  birthday: string | null;
  locale: 'en' | 'he' | null;
  enableCommunity: boolean;
  locationTracking: boolean;
  avatar: string | null;
};

export const SettingsPage = () => {
  const { t } = useTranslation();
  const { data: user, isLoading: isLoadingUser } = useCurrentUser();
  const { mutate: updateCurrentUser, isLoading: isSavingUser } =
    useUpdateCurrentUser();
  const [currentAvatar, setCurrentAvatar] = useState<AvatarType | undefined>(
    undefined
  );

  const [open, setOpen] = useState(false);

  const logoutOfApp = () => {
    auth.signOut();
  };

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isDirty },
  } = useForm<UserInput>({
    resolver: yupResolver(schema(t)),
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });

  useEffect(() => {
    if (user) {
      reset({
        name: user.name,
        email: user.email,
        phoneNumber: user.phoneNumber,
        birthday: user.birthday,
        locale: user.locale,
        enableCommunity: user.enableCommunity,
        locationTracking: user.locationTracking,
      });
    }
  }, [user]);

  const onSubmit = (values: UserInput) => {
    updateCurrentUser(
      {
        ...values,
        avatar: currentAvatar?.key,
        birthday: dateWithoutTime(values.birthday),
      },
      {
        onSuccess: () => {
          toast.success(t('general.saved'), {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            icon: false,
            theme: 'colored',
          });
        },
      }
    );
  };

  return (
    <>
      <div className="container-lg p-10" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-8">
          <h1 className="text-3xl font-semibold text-left ">
            {t('settings.title')}
          </h1>
        </div>
        <form id="users-settings-form" className="flex flex-row grow">
          <div className="bg-white flex flex-col rounded-lg basis-2/3 px-8 py-8">
            <p className="text-[1.125rem] font-semibold mb-5">
              {t('settings.profileDetails')}
            </p>
            <div className="bg-grey-500 py-6 px-8 rounded-lg">
              <Avatar
                onClick={() => {
                  setOpen(true);
                }}
                className="cursor-pointer"
                src={currentAvatar?.url || user?.imageUrl || ''}
                alt="profile-avatar"
                sx={{ width: 140, height: 140 }}
              />
            </div>
            <div className="p-8 flex flex-col">
              <label className="mb-4 text-grey-700" htmlFor="email">
                {t('settings.fullName')}
              </label>
              <TextField
                className="!border-2"
                placeholder="Your name"
                id="name"
                type="text"
                data-testid="name-input"
                variant="outlined"
                error={!!errors?.name?.message}
                helperText={errors?.name?.message}
                {...register('name')}
              />
              <label className="mt-6 mb-4 text-grey-700" htmlFor="email">
                {t('settings.yourEmail')}
              </label>
              <TextField
                className="!border-2"
                placeholder="Your email"
                id="email"
                type="text"
                data-testid="email-input"
                variant="outlined"
                error={!!errors?.email?.message}
                helperText={errors?.email?.message}
                {...register('email')}
              />
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <label className="mt-6 mb-4 text-grey-700" htmlFor="email">
                      {t('settings.phone')}
                    </label>
                    <PhoneInputStyled
                      className="p-4 border border-secondary/30 rounded"
                      placeholder={t('settings.phone')}
                      value={value as any}
                      onChange={onChange}
                    />
                    {errors?.phoneNumber?.message && (
                      <p className="text-red-600 text-xs ltr:ml-3 rtl:mr-3 mt-1">
                        {errors?.phoneNumber?.message}
                      </p>
                    )}
                  </>
                )}
              />
              <label className="mt-6 mb-4 text-grey-700" htmlFor="email">
                {t('settings.birthDate')}
              </label>
              <TextField
                className="!border-2"
                placeholder={t('settings.birthDate') as string}
                id="birthDate"
                type="date"
                data-testid="birth-date-input"
                variant="outlined"
                error={!!errors?.birthday?.message}
                helperText={errors?.birthday?.message}
                {...register('birthday')}
              />
            </div>
          </div>
          <div className="basis-1/3 flex flex-col ltr:ml-10 rtl:mr-10">
            <div className="bg-white flex flex-col rounded-lg mb-5 basis-1/2 p-8 ">
              <p className="text-[1.125rem] font-semibold mb-8">
                {t('settings.preferences')}
              </p>
              <div className="flex flex-col items-start">
                <Controller
                  name="locale"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <div className="flex flex-row">
                      <span>{t('settings.language')}</span>
                      <SelectStyled
                        className="flex flex-grow ltr:!ml-4 rtl:!mr-4"
                        value={value || 'en'}
                        onChange={onChange}
                      >
                        <MenuItem value="en">English</MenuItem>
                        <MenuItem value="he">עִברִית</MenuItem>
                      </SelectStyled>
                    </div>
                  )}
                />
                <Controller
                  name="enableCommunity"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <div className="flex flex-row items-center mt-4">
                      <span>{t('settings.enableCommunity')}</span>
                      <Switch
                        className="flex flex-grow ltr:!ml-4 rtl:!mr-4"
                        checked={!!value}
                        onChange={onChange}
                      />
                    </div>
                  )}
                />
                <Controller
                  name="locationTracking"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <div className="flex flex-row items-center mt-4">
                      <span>{t('settings.locationTracking')}</span>
                      <Switch
                        className="flex flex-grow ltr:!ml-4 rtl:!mr-4"
                        checked={!!value}
                        onChange={onChange}
                      />
                    </div>
                  )}
                />
              </div>
            </div>
            {/* <div className="bg-white rounded-lg mt-5 basis-1/2 p-8">
              <p className="text-[1.125rem] font-semibold mb-8">
                {t('settings.orgLogo')}
              </p>
              <div className="p-8 flex flex-row justify-center items-center">
                <img src="/orgLogo.png" alt="sideNavLogo" />
              </div>
            </div> */}
          </div>
        </form>
        <div>
          <div className="flex flex-row justify-end mt-8">
            <Button
              data-testid="login-button"
              className="!bg-primary/[.1] !text-primary !py-3 !px-12 ltr:!mr-4 rtl:!ml-4 !rounded-md !normal-case !text-base"
              variant="contained"
              disabled={isLoadingUser || isSavingUser}
              onClick={logoutOfApp}
            >
              {t('general.logOut')}
            </Button>
            <LoadingButton
              form="users-settings-form"
              data-testid="login-button"
              className="!bg-primary !py-3 !px-7 !rounded-md !normal-case !text-base"
              variant="contained"
              type="submit"
              disabled={isSavingUser || isLoadingUser}
              loading={isSavingUser}
            >
              {t('settings.saveChanges')}
            </LoadingButton>
          </div>
        </div>
      </div>
      <AvatarDialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        setSelected={setCurrentAvatar}
      />
    </>
  );
};
