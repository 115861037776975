import clsx from 'clsx';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useJsApiLoader, GoogleMap, Marker } from '@react-google-maps/api';
import { ActiveGuardian, Event } from 'src/repos/events';

type Props = {
  className?: string;
  event?: Event;
  guardians?: ActiveGuardian[] | null;
};

export const Map: FC<Props> = (props) => {
  const { className, event, guardians } = props;
  const GoogleMapRef = useRef<HTMLDivElement>(null);
  const [libraries] = useState<
    ('places' | 'drawing' | 'geometry' | 'localContext' | 'visualization')[]
  >(['places']);
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACE_API_KEY as string,
    libraries,
  });
  const [zoom, setZoom] = useState(15);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [center, setCenter] = useState({
    lng: 0,
    lat: 0,
  });
  const onLoad = useCallback(function callback(map: google.maps.Map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map: google.maps.Map) {
    setMap(null);
  }, []);

  useEffect(() => {
    if (map && event) {
      setCenter({ lat: event.latitude, lng: event.longitude });
    }
  }, [map, event]);

  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  return (
    <div
      className={clsx(
        className,
        'flex flex-col justify-center text-center rounded-lg drop-shadow-md overflow-hidden w-full h-full'
      )}
      ref={GoogleMapRef}
    >
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={zoom}
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={{
            minZoom: 3,
            maxZoom: 17,
          }}
        >
          {center.lat ? (
            <Marker
              title={'location'}
              position={center}
              icon={'/locationMarker.svg'}
              onClick={() => {
                const markerBounds = new google.maps.LatLngBounds();
                markerBounds.extend(
                  new google.maps.LatLng(center.lat, center.lng)
                );
                map?.fitBounds(markerBounds);
              }}
            />
          ) : null}
          {guardians
            ? guardians.map((guardian) =>
                guardian.latitude && guardian.longitude ? (
                  <Marker
                    key={guardian.activeGuardianId}
                    title={'guardian'}
                    position={{
                      lat: guardian.latitude,
                      lng: guardian.longitude,
                    }}
                    icon={'/runningMan.svg'}
                    onClick={() => {
                      if (guardian.latitude && guardian.longitude) {
                        const markerBounds = new google.maps.LatLngBounds();
                        markerBounds.extend(
                          new google.maps.LatLng(
                            guardian.latitude,
                            guardian.longitude
                          )
                        );
                        map?.fitBounds(markerBounds);
                      }
                    }}
                  />
                ) : null
              )
            : null}
        </GoogleMap>
      )}
    </div>
  );
};
