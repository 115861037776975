import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

type Option = {
  key: string;
  value: string | number;
};

type FilterProps = {
  setFilter: ActionCreatorWithPayload<any, string>;
  removeFilter: ActionCreatorWithPayload<any, string>;
  name: string;
  placeholder?: string;
  options?: Option[];
};

export const TextSearchFilter: FC<FilterProps> = (props) => {
  const { setFilter, removeFilter, name, placeholder } = props;
  const dispatch = useDispatch();

  return (
    <input
      className="mt-2 font-normal px-2 py-1.5 rounded-md"
      placeholder={placeholder}
      onChange={(e) => {
        if (e.target.value) {
          dispatch(setFilter({ [name]: e.target.value }));
        } else {
          dispatch(removeFilter(name));
        }
      }}
      style={{ width: '100%' }}
    />
  );
};

export const SelectFilter: FC<FilterProps> = (props) => {
  const { setFilter, removeFilter, name, options } = props;
  const dispatch = useDispatch();

  const { t } = useTranslation();

  return (
    <select
      className="mt-2 px-2 py-1.5 font-normal rounded-md"
      defaultValue={'All'}
      onChange={(e) => {
        if (e.target.value !== 'All') {
          dispatch(setFilter({ [name]: e.target.value }));
        } else {
          dispatch(removeFilter(name));
        }
      }}
    >
      <option key={'All'} value={'All'}>
        {t('table.all')}
      </option>
      {options?.map(({ key, value }) => {
        return (
          <option key={key} value={value}>
            {key}
          </option>
        );
      })}
    </select>
  );
};
