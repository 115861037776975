import styled from '@emotion/styled';
import { Select } from '@mui/material';
import PhoneInput from 'react-phone-number-input';

export const SelectStyled = styled(Select)`
  .MuiOutlinedInput-input {
    padding: 0.5rem 1rem;
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
    border-width: 1px;
  }
`;

export const PhoneInputStyled = styled(PhoneInput)`
  input {
    margin-left: 4px;
    outline: none;
  }
`;
