import { CallEndTwoTone, CallTwoTone } from '@mui/icons-material';
import {
  Avatar,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Call } from '@twilio/voice-sdk';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { secondsToTime } from 'src/app/utils';
import { useTwilioIdentity } from 'src/repos/twilio';
import { DialogStyled } from '../Chat/styles';

type Props = {
  open: boolean;
  onClose: () => void;
  call: Call;
  callerId: string | undefined;
};

export const CallDialog: FC<Props> = (props) => {
  const { onClose, open, call, callerId } = props;
  const { t } = useTranslation();
  const [callSeconds, setCallSeconds] = useState(0);
  const callTimerInterval = useRef<NodeJS.Timer | null>(null);
  const { data: callerIdentity } = useTwilioIdentity(callerId);

  const isIcomingCall = call.direction === 'INCOMING';

  const clearTimer = () => {
    if (callTimerInterval.current) {
      clearInterval(callTimerInterval.current);
    }
  };

  useEffect(() => {
    if (isIcomingCall) {
      call.on('cancel', () => {
        call.disconnect();
        clearTimer();
        onClose();
      });
    }
    call.on('accept', (s) => {
      if (!callTimerInterval.current) {
        // extra check for strict mode
        callTimerInterval.current = setInterval(() => {
          setCallSeconds((state) => ++state);
        }, 1000);
      }
    });
    call.on('disconnect', (call) => {
      call.disconnect();
      clearTimer();
      onClose();
    });
  }, []);

  return (
    <DialogStyled
      onClose={(e, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          onClose();
        }
      }}
      open={open}
    >
      <DialogTitle className="text-center">
        {isIcomingCall ? t('call.titleIncoming') : t('call.titleOutgoing')}
      </DialogTitle>
      <DialogContent
        dividers
        className="flex flex-col justify-center items-center p-8"
      >
        <Avatar
          alt={callerIdentity?.name || ''}
          src={callerIdentity?.imageUrl || ''}
          sx={{ width: 96, height: 96 }}
        />
        <span className="bold text-2xl mt-4">
          {callerIdentity?.name || t('general.unknown')}
        </span>
        {callSeconds ? (
          <span className="bold mt-4">
            {secondsToTime(callSeconds).h}:{secondsToTime(callSeconds).m}:
            {secondsToTime(callSeconds).s}
          </span>
        ) : null}
      </DialogContent>
      <DialogActions className="!p-4 w-72 flex flex-row !justify-center">
        {!callSeconds && isIcomingCall ? (
          <>
            <Button
              variant="contained"
              startIcon={<CallTwoTone />}
              className="!bg-green-500 !rounded-2xl"
              onClick={() => {
                call.accept();
              }}
            >
              {t('call.accept')}
            </Button>
            <Button
              variant="contained"
              startIcon={<CallEndTwoTone />}
              className="!bg-red-500 !rounded-2xl"
              onClick={() => {
                call.reject();
                onClose();
              }}
            >
              {t('call.decline')}
            </Button>
          </>
        ) : (
          <Button
            variant="contained"
            startIcon={<CallEndTwoTone />}
            className="!bg-red-500 !rounded-2xl"
            onClick={() => {
              call.disconnect();
              clearTimer();
              onClose();
            }}
          >
            {t('call.end')}
          </Button>
        )}
      </DialogActions>
    </DialogStyled>
  );
};
