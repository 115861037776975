import { FC } from 'react';
import { LoaderStyled } from './styles';

export const Loader: FC = () => {
  return (
    <div className="flex flex-col h-full w-full grow justify-center items-center">
      <img src="/appTitle.png" alt="logo" />
      <div className="w-full flex justify-center p-4">
        <LoaderStyled className="w-40" />
      </div>
    </div>
  );
};
