import { NotificationsNoneOutlined } from '@mui/icons-material';
import { Badge, IconButton, Menu } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationWrapper } from 'src/components/Notification/Wrapper';
import { useNotificationsContext } from 'src/components/NotificationWrapper';
import { useUserContext } from 'src/components/ProtectedRoute';

export const NotificationsMenu: FC = () => {
  const { t } = useTranslation();

  const { notifications } = useNotificationsContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { user } = useUserContext();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Badge
        badgeContent={notifications.length}
        color="primary"
        className="mx-7"
      >
        <IconButton
          className="!p-0"
          onClick={handleClick}
          size="small"
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <NotificationsNoneOutlined className="text-primary" />
        </IconButton>
      </Badge>

      <Menu
        anchorEl={anchorEl}
        id="notifications-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        className="p-5"
        PaperProps={{
          elevation: 0,
          sx: {
            padding: '1.25rem',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
              minHeight: 300,
              maxHeight: 300,
              minWidth: 400,
              maxWidth: 400,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div className="mb-5">
          <span className="text-secondary/60">{t('navbar.notifications')}</span>
        </div>
        <div
          className="overflow-auto"
          style={{
            minHeight: 300,
            maxHeight: 300,
            minWidth: 400,
            maxWidth: 400,
          }}
        >
          {notifications.map((notification, i) => (
            <NotificationWrapper
              key={`${notification?.data?.id}-${i}`}
              notification={notification}
              locale={user?.locale || 'en'}
            />
          ))}
        </div>
      </Menu>
    </>
  );
};
