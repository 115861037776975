import { FormControl, InputLabel, MenuItem } from '@mui/material';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AppDispatch } from 'src/app/store';
import { dateWithoutTime } from 'src/app/utils';
import { useNotificationsContext } from 'src/components/NotificationWrapper';
import { ReportDialog } from 'src/components/Reports/Dialog';
import { Table } from 'src/components/Table';
import { TextSearchFilter } from 'src/components/Table/utils';
import { Report, useReport } from 'src/repos/reports';
import {
  fetchReports,
  selectPageSize,
  selectReports,
  selectPageIndex,
  selectPagesCount,
  setFilter,
  selectFilters,
  removeFilter,
} from './slice';
import { SelectStyled } from './styles';

export const ReportsPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const reports = useSelector(selectReports);
  const { search } = useLocation();

  const { t } = useTranslation();
  const query = useMemo(() => new URLSearchParams(search), [search]);

  const [open, setOpen] = useState(false);
  const [currentReport, setCurrentReport] = useState<Report | undefined>();

  const serverPageSize = useSelector(selectPageSize);
  const serverPageIndex = useSelector(selectPageIndex);
  const serverPagesCount = useSelector(selectPagesCount);

  const filters = useSelector(selectFilters);

  const { notifications } = useNotificationsContext();

  const { data: report } = useReport(
    query.get('id') ? Number(query.get('id')) : undefined
  );

  useEffect(() => {
    if (report) {
      setOpen(true);
      setCurrentReport(report);
    }
  }, [report]);

  useEffect(() => {
    if (notifications?.length > 0) {
      const lastNoficiation = notifications.at(-1);
      if (lastNoficiation?.data?.type === 'REPORT') {
        dispatch(
          fetchReports({
            filters,
            params: { page: 0, size: 10 },
          })
        );
      }
    }
  }, [notifications]);

  const data = reports?.content || [];
  const columns = [
    {
      Header: t('table.id'),
      accessor: 'reportId',
      Filter: (
        <TextSearchFilter
          setFilter={setFilter}
          removeFilter={removeFilter}
          name="reportId"
          placeholder={t('table.id') as string}
        />
      ),
    },
    {
      Header: t('reports.columns.userId'),
      accessor: 'userId',
      Filter: (
        <TextSearchFilter
          setFilter={setFilter}
          removeFilter={removeFilter}
          name="userId"
          placeholder={t('reports.columns.userId') as string}
        />
      ),
    },
    {
      Header: t('reports.columns.address'),
      accessor: 'address',
      Filter: (
        <TextSearchFilter
          setFilter={setFilter}
          removeFilter={removeFilter}
          name="address"
          placeholder={t('reports.columns.address') as string}
        />
      ),
    },
    {
      Header: t('reports.columns.summary'),
      accessor: 'summary',
      Filter: (
        <TextSearchFilter
          setFilter={setFilter}
          removeFilter={removeFilter}
          name="summary"
          placeholder={t('reports.columns.summary') as string}
        />
      ),
    },
    {
      Header: t('general.createdAt'),
      accessor: 'createdAt',
      Cell: (props: { row: { original: Report } }) =>
        dateWithoutTime(props.row.original.createdAt, '/'),
    },
    {
      Header: t('general.updatedAt'),
      accessor: 'updatedAt',
      Cell: (props: { row: { original: Report } }) =>
        dateWithoutTime(props.row.original.updatedAt, '/'),
    },
  ];

  return (
    <>
      <div className="flex flex-row justify-start items-center px-10">
        <p className="mr-2"> {t('reports.show')}</p>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel htmlFor="event-type-select">
            {t('reports.type')}
          </InputLabel>
          <SelectStyled
            id="event-type-select"
            label="Event type"
            defaultValue="0"
            onChange={(event) => {
              const value = event.target.value as string;
              if (value === '0' && filters.linked !== null) {
                dispatch(setFilter({ linked: null }));
                dispatch(
                  fetchReports({
                    filters: { linked: null, active: filters.active },
                    params: { page: 0, size: 10 },
                  })
                );
              }
              if (value === '1' && filters.linked !== true) {
                dispatch(setFilter({ linked: true }));
                dispatch(
                  fetchReports({
                    filters: { linked: true, active: filters.active },
                    params: { page: 0, size: 10 },
                  })
                );
              }
              if (value === '2' && filters.linked !== false) {
                dispatch(setFilter({ linked: false }));
                dispatch(
                  fetchReports({
                    filters: { linked: false, active: filters.active },
                    params: { page: 0, size: 10 },
                  })
                );
              }
            }}
          >
            <MenuItem value="1">{t('reports.assigned')}</MenuItem>
            <MenuItem value="2">{t('reports.notAssigned')}</MenuItem>
            <MenuItem value="0">{t('reports.all')}</MenuItem>
          </SelectStyled>
        </FormControl>
      </div>
      <div className="w-full flex flex-col grow items-center">
        <div className="flex flex-row justify-center items-center">
          <div
            className={clsx(
              filters.active
                ? 'bg-primary text-white'
                : 'bg-primary/10 text-primary',
              'py-5 px-20 cursor-pointer'
            )}
            onClick={() => {
              if (!filters.active) {
                dispatch(setFilter({ active: true }));
                dispatch(
                  fetchReports({
                    filters: { active: true, linked: filters.linked },
                    params: { page: 0, size: 10 },
                  })
                );
              }
            }}
          >
            {t('reports.active')}
          </div>
          <div
            className={clsx(
              !filters.active
                ? 'bg-primary text-white'
                : 'bg-primary/10 text-primary',
              'py-5 px-20 cursor-pointer'
            )}
            onClick={() => {
              if (filters.active) {
                dispatch(setFilter({ active: false }));
                dispatch(
                  fetchReports({
                    filters: { active: false, linked: filters.linked },
                    params: { page: 0, size: 10 },
                  })
                );
              }
            }}
          >
            {t('reports.past')}
          </div>
        </div>
        <div className="bg-white rounded-lg p-8 m-10 overflow-x-auto flex grow">
          <Table
            data={data}
            columns={columns}
            serverPageIndex={serverPageIndex || 0}
            serverPageSize={serverPageSize || 10}
            serverPagesCount={serverPagesCount || 1}
            fetch={(params) => {
              dispatch(fetchReports(params));
            }}
            filters={filters}
            onRowClick={(report) => {
              setOpen(true);
              setCurrentReport(report as Report);
            }}
          />
          <ReportDialog
            open={open}
            report={currentReport}
            onClose={() => {
              setOpen(false);
              setCurrentReport(undefined);
            }}
          />
        </div>
      </div>
    </>
  );
};
