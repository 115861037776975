import { Button } from '@mui/material';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactTimeAgo from 'react-time-ago';
import { AppDispatch } from 'src/app/store';
import { dateWithoutTime } from 'src/app/utils';
import { EventDialog } from 'src/components/EventDialog';
import { useNotificationsContext } from 'src/components/NotificationWrapper';
import { useUserContext } from 'src/components/ProtectedRoute';
import { Table } from 'src/components/Table';
import { SelectFilter, TextSearchFilter } from 'src/components/Table/utils';
import { Event } from 'src/repos/events';
import {
  fetchEvents,
  selectPageSize,
  selectEvents,
  selectPageIndex,
  selectPagesCount,
  setFilter,
  selectFilters,
  removeFilter,
} from './slice';

export const EventsPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const events = useSelector(selectEvents);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const serverPageSize = useSelector(selectPageSize);
  const serverPageIndex = useSelector(selectPageIndex);
  const serverPagesCount = useSelector(selectPagesCount);

  const filters = useSelector(selectFilters);

  const [open, setOpen] = useState(false);

  const { notifications } = useNotificationsContext();

  const { user } = useUserContext();

  useEffect(() => {
    if (notifications?.length > 0) {
      const lastNoficiation = notifications.at(-1);
      if (
        lastNoficiation?.data?.type === 'EVENT' ||
        lastNoficiation?.data?.type === 'EVENT_RESOLVED'
      ) {
        dispatch(
          fetchEvents({
            filters,
            params: { page: 0, size: 10 },
          })
        );
      }
    }
  }, [notifications]);

  const Status = (props: { row: { original: Event } }, column: string) => {
    const {
      row: { original },
    } = props;

    const Tag = ({ className, text }: { className: string; text: string }) => (
      <div
        className={clsx(
          className,
          'py-2 rounded-[2.625rem] text-center truncate px-2'
        )}
      >
        <span className="truncate">{text} </span>
      </div>
    );

    if (column === 'community') {
      return original.community ? (
        <Tag
          className="bg-green-700/[.15] text-green-700"
          text={t('events.columns.community')}
        />
      ) : (
        <Tag
          className="bg-grey-100/[.4] text-grey-600"
          text={t('general.notCommunity')}
        />
      );
    }

    return column === 'active' ? (
      original.active ? (
        <Tag
          className="bg-green-700/[.15] text-green-700"
          text={t('general.active')}
        />
      ) : (
        <Tag
          className="bg-grey-100/[.4] text-grey-600"
          text={t('general.inactive')}
        />
      )
    ) : original.expired ? (
      <Tag
        className="bg-red-700/[.4] text-red-700"
        text={t('general.expired')}
      />
    ) : (
      <Tag
        className="bg-green-700/[.15] text-green-700"
        text={t('general.ongoing')}
      />
    );
  };

  const data = events?.content || [];
  const columns = [
    {
      Header: t('table.id'),
      accessor: 'eventId',
      Filter: (
        <TextSearchFilter
          setFilter={setFilter}
          removeFilter={removeFilter}
          name="eventId"
          placeholder={t('table.id') as string}
        />
      ),
    },
    {
      Header: t('events.columns.userId'),
      accessor: 'userId',
      Filter: (
        <TextSearchFilter
          setFilter={setFilter}
          removeFilter={removeFilter}
          name="userId"
          placeholder={t('events.columns.userId') as string}
        />
      ),
    },
    {
      Header: t('events.columns.userName'),
      accessor: 'userName',
    },
    {
      Header: t('general.active'),
      accessor: 'active',
      Cell: (props: { row: { original: Event } }) => Status(props, 'active'),
    },
    {
      Header: t('general.expired'),
      accessor: 'expired',
      Filter: (
        <SelectFilter
          setFilter={setFilter}
          removeFilter={removeFilter}
          name="expired"
          options={[
            { key: t('general.expired'), value: 'true' },
            { key: t('general.ongoing'), value: 'false' },
          ]}
        />
      ),
      Cell: (props: { row: { original: Event } }) => Status(props, 'expired'),
    },
    {
      Header: t('events.columns.community'),
      accessor: 'community',
      Cell: (props: { row: { original: Event } }) => Status(props, 'community'),
    },
    {
      Header: t('general.createdAt'),
      accessor: 'createdAt',
      Cell: (props: { row: { original: Event } }) => (
        <ReactTimeAgo
          date={
            props.row.original.createdAt
              ? new Date(props.row.original.createdAt)
              : new Date()
          }
          locale={user?.locale || 'en'}
        />
      ),
    },
    {
      Header: t('events.columns.resolvedAt'),
      accessor: 'resolvedAt',
      Cell: (props: { row: { original: Event } }) =>
        dateWithoutTime(props.row.original.resolvedAt, '/'),
    },
  ];

  return (
    <>
      {' '}
      <div className="container-lg mx-auto flex flex-col grow items-center">
        <div className="justify-start flex flex-row items-start w-full p-4">
          <Button
            onClick={() => {
              setOpen(true);
            }}
            variant="contained"
          >
            {t('event.dialog.title')}
          </Button>
        </div>
        <div className="flex flex-row justify-center">
          <div
            className={clsx(
              filters.active
                ? 'bg-primary text-white'
                : 'bg-primary/10 text-primary',
              'py-5 px-20 cursor-pointer'
            )}
            onClick={() => {
              if (!filters.active) {
                dispatch(setFilter({ active: true }));
                dispatch(
                  fetchEvents({
                    filters: { active: true },
                    params: { page: 0, size: 10 },
                  })
                );
              }
            }}
          >
            {t('events.active')}
          </div>
          <div
            className={clsx(
              !filters.active
                ? 'bg-primary text-white'
                : 'bg-primary/10 text-primary',
              'py-5 px-20 cursor-pointer'
            )}
            onClick={() => {
              if (filters.active) {
                dispatch(setFilter({ active: false }));
                dispatch(
                  fetchEvents({
                    filters: { active: false },
                    params: { page: 0, size: 10 },
                  })
                );
              }
            }}
          >
            {t('events.past')}
          </div>
        </div>
        <div className="bg-white rounded-lg p-8 m-10 flex grow">
          <Table
            data={data}
            columns={columns}
            serverPageIndex={serverPageIndex || 0}
            serverPageSize={serverPageSize || 10}
            serverPagesCount={serverPagesCount || 1}
            fetch={(params) => {
              dispatch(fetchEvents(params));
            }}
            filters={filters}
            onRowClick={(event) => {
              navigate(`/events/${(event as Event).eventId}`);
            }}
          />
        </div>
      </div>
      <EventDialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onSuccess={() => {
          dispatch(setFilter({ active: true }));
          dispatch(
            fetchEvents({
              filters: { active: true },
              params: { page: 0, size: 10 },
            })
          );
        }}
      />
    </>
  );
};
