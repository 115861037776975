import { Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  TextField,
} from '@mui/material';
import Select, { SingleValue } from 'react-select';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// import GooglePlacesAutocomplete, {
//   geocodeByPlaceId,
// } from 'react-google-places-autocomplete';
import {
  Report,
  useCreateEventReport,
  useCreateReport,
  useCreateReportAttachement,
  useReportsTypes,
  useUploadReportAttachement,
} from 'src/repos/reports';
import { useQueryClient } from 'react-query';
import Geocode from 'react-geocode';
import { Event } from 'src/repos/events';
import { useCurrentUser } from 'src/repos/users';

type Props = {
  open: boolean;
  onClose: () => void;
  event?: Event;
};

type Location = {
  formatted_address: string;
  place_id: string;
  geometry: {
    location: {
      lat: number;
      lng: number;
    };
  };
  value: {
    place_id: string;
  };
};

type ReportInput = {
  address?: Location | null;
  type: string;
  summary: string;
};

export type SelectOption = { value?: string; label?: string };

export const ReportFormDialog: FC<Props> = (props) => {
  const { onClose, open, event } = props;
  const { eventId, latitude, longitude } = { ...event };
  const { t } = useTranslation();
  const [attachments, setAttachments] = useState<File[]>([]);
  const [currentAddress, setCurrentAddress] = useState<Location | null>();
  const [attachmentsDisplay, setAttachmentsDisplay] = useState<
    { url: string; type: 'img' | 'video' }[]
  >([]);
  const [attachmentError, setAttachmentError] = useState(false);
  const { mutate: createReport, isLoading: isCreatingReport } =
    useCreateReport();
  const { mutate: createEventReport, isLoading: isCreatingEventReport } =
    useCreateEventReport(eventId);
  const { mutateAsync: getUploadUrl, isLoading: isCreatingAttachments } =
    useCreateReportAttachement();
  const { mutateAsync: uploadAttachment, isLoading: isUploadingAttachments } =
    useUploadReportAttachement();
  const queryClient = useQueryClient();

  const { data: user } = useCurrentUser();
  const { data: types } = useReportsTypes(user?.locale || 'en');

  const options: SelectOption[] = useMemo(
    () =>
      types?.map((option, i) => ({
        value: option,
        label: option,
      })) || [],
    [types]
  );
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isDirty },
    setValue,
  } = useForm<ReportInput>({
    //resolver: yupResolver(schema(t)),
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });

  const isLoading =
    isCreatingReport ||
    isCreatingAttachments ||
    isUploadingAttachments ||
    isCreatingEventReport;

  const uploadPhotos = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { files },
    } = event;

    if (files && files.length > 0) {
      const attachment = files[0];
      if (attachment.size > 10485760) {
        // 10MB
        setAttachmentError(true);
        return;
      }
      setAttachments([...attachments, attachment]);
      setAttachmentsDisplay([
        ...attachmentsDisplay,
        {
          url: URL.createObjectURL(attachment),
          type: attachment.type.includes('image') ? 'img' : 'video',
        },
      ]);
    }
  };

  // const prefillAddress = () => {
  //   Geocode.setApiKey(process.env.REACT_APP_GOOGLE_PLACE_API_KEY as string);

  //   // set response language. Defaults to english.
  //   Geocode.setLanguage('en');
  //   navigator.geolocation.getCurrentPosition(function (position) {

  //   });
  // };

  useEffect(() => {
    // cleanup
    return () =>
      attachmentsDisplay.forEach(
        (file: { url: string; type: 'img' | 'video' }) =>
          URL.revokeObjectURL(file.url)
      );
  }, []);

  const onSuccess = async (report: Report) => {
    const attcMutations = attachments.map(async (attachment) => {
      const upload = await getUploadUrl({
        attachmentKey: attachment.name,
        reportId: report.reportId,
      });
      return uploadAttachment(
        {
          image: attachment,
          uploadUrl: upload.uploadUrl,
        },
        {
          onSuccess: async (url) => {
            setAttachmentError(false);
            attachmentsDisplay.forEach(
              (file: { url: string; type: 'img' | 'video' }) =>
                URL.revokeObjectURL(file.url)
            );
            setAttachments([]);
            setAttachmentsDisplay([]);
          },
        }
      );
    });
    await Promise.all(attcMutations).finally(() => {
      queryClient.invalidateQueries(`eventReports#${eventId}`);
      onClose();
      reset({ address: currentAddress, summary: '' });
    });
  };

  const saveReport = (report: Partial<Report>) => {
    if (eventId) {
      createEventReport(
        { data: report },
        {
          onSuccess,
        }
      );
    } else {
      createReport(report, {
        onSuccess,
      });
    }
  };

  const onSubmit = (values: ReportInput) => {
    // TODO: does the address matter?

    // if (!values?.address?.geometry) {
    //   Geocode.fromLatLng(
    //     position?.coords?.latitude?.toString(),
    //     position?.coords?.longitude?.toString()
    //   ).then(
    //     (response) => {
    //       setCurrentAddress(response.results[0]);
    //       setValue('address', response.results[0]);
    //     },
    //     (error) => {
    //       console.error(error);
    //     }
    //   );

    // } else {
    if (event) {
      const report = {
        summary: values.summary,
        type: values.type,
        longitude,
        latitude,
        //address: values?.address?.formatted_address,
      };
      saveReport(report);
    } else {
      navigator.geolocation.getCurrentPosition(function (position) {
        saveReport({
          summary: values.summary,
          type: values.type,
          longitude: position?.coords?.longitude,
          latitude: position?.coords?.latitude,
        });
      });
    }
    //}
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      className="!rounded-lg"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className="flex flex-row justify-between items-center !p-7 !bg-grey-500">
        {t('report.new')}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={() => {
              reset({ summary: '', type: '' });
              setAttachmentError(false);
              setAttachments([]);
              attachmentsDisplay.forEach(
                (file: { url: string; type: 'img' | 'video' }) =>
                  URL.revokeObjectURL(file.url)
              );
              setAttachmentsDisplay([]);
              onClose();
            }}
            className="!bg-primary/10 !text-primary"
            disabled={isLoading}
          >
            <Close className="" />
          </IconButton>
        ) : null}
      </DialogTitle>
      <form
        id="report-form"
        className="flex flex-row grow"
        onSubmit={handleSubmit(onSubmit)}
        style={{ minHeight: '25rem' }}
      >
        <div className="!bg-grey-500 flex flex-col rounded-lg grow px-8 py-8">
          <p className="text-xl font-bold mb-2">{t('report.help')}</p>
          <p className="text-base mb-5">{t('report.helpDescription')}</p>

          <div className="flex flex-col h-full justify-between">
            <Controller
              name="type"
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <Select
                  isRtl={user?.locale === 'he'}
                  ref={ref}
                  placeholder={t('report.tellUs')}
                  value={
                    value
                      ? ({ value: value, label: value } as SelectOption)
                      : null
                  }
                  onChange={(option: SingleValue<SelectOption>) => {
                    if (option) {
                      onChange(option.value);
                    }
                  }}
                  isSearchable={true}
                  formatOptionLabel={({ value }) => <span>{value}</span>}
                  options={options}
                />
              )}
            />
            <div className="flex flex-col">
              <label className="mt-6 mb-4 text-grey-700" htmlFor="email">
                {t('report.description')}
              </label>
              <TextField
                multiline
                minRows={4}
                className="!border-2"
                placeholder={t('report.what') as string}
                id="summary"
                type="text"
                data-testid="summary-input"
                variant="outlined"
                error={!!errors?.summary?.message}
                helperText={errors?.summary?.message}
                {...register('summary')}
              />
              <label className="mt-6 mb-4 text-grey-700" htmlFor="email">
                {t('report.addPhotoOrVideo')}
              </label>
              <input
                className="!border-2 p-4"
                placeholder={t('report.addPhotoOrVideo') as string}
                id="attachments"
                type="file"
                data-testid="photo-input"
                onChange={uploadPhotos}
                accept="image/*, video/*"
              />
              {attachmentError ? (
                <span className="text-red-500">{t('report.tooLarge')}</span>
              ) : null}
              <div className="flex flex-row">
                {attachmentsDisplay.map((att, i) => (
                  <div key={i}>
                    {att.type === 'img' ? (
                      <img
                        className="p-4"
                        src={att.url}
                        style={{ width: 100, height: 100, objectFit: 'cover' }}
                      />
                    ) : (
                      <video
                        style={{
                          width: 100,
                          height: 100,
                          objectFit: 'cover',
                        }}
                        className={'p-4'}
                      >
                        <source src={att.url} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </form>
      {isLoading ? (
        <div className="px-8 bg-grey-500">
          <p className="text-center">{t('report.submiting')}</p>
          <LinearProgress />
        </div>
      ) : null}
      <DialogActions className="!p-7 flex flex-row !bg-grey-500">
        <Button
          className="!bg-primary/10 !text-primary !py-2 !px-7 text-center !normal-case"
          onClick={() => {
            reset({ summary: '', type: '' });
            setAttachmentError(false);
            setAttachments([]);
            attachmentsDisplay.forEach(
              (file: { url: string; type: 'img' | 'video' }) =>
                URL.revokeObjectURL(file.url)
            );
            setAttachmentsDisplay([]);
            onClose();
          }}
          disabled={isLoading}
        >
          {t('general.cancel')}
        </Button>
        <Button
          form="report-form"
          className="!bg-primary !text-white !py-2 !px-7 text-center !normal-case rtl:mr-2 rtl:!ml-0"
          type="submit"
          disabled={isLoading}
        >
          {t('report.send')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
