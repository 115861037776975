import { MessageTwoTone } from '@mui/icons-material';
import { Avatar, Divider } from '@mui/material';
import clsx from 'clsx';
import { FC } from 'react';
import { dateToLocaleString } from 'src/app/utils';
import { Event as EventType } from 'src/repos/events';
import { useCurrentUser, useUser } from 'src/repos/users';
import { Guardians } from '../Guardians';
import { Detail } from '../Shared/Detail';
import { UserActionBar } from '../Shared/UserActionBar';
import { IconButton } from '@mui/material';
import { useTwilioContext } from 'src/components/TwilioContextProvider';
import { useJoinEventConversation } from 'src/repos/events';

import { UserAvatarWrapper } from './styles';
import { t } from 'i18next';

type Props = {
  event: EventType | undefined;
  className?: string;
};

export const Event: FC<Props> = (props) => {
  const { className, event } = props;
  const { eventId, conversationSid } = { ...event };

  const { chatClient, startChat } = useTwilioContext();

  const { data: user } = useCurrentUser();
  const { data: eventOnwerProfile } = useUser(event?.userId);
  const { mutate: joinEventConversation } = useJoinEventConversation();

  const EventChat = ({ color }: { color?: string }) => {
    return (
      <IconButton
        onClick={async () => {
          if (conversationSid) {
            await chatClient
              ?.getConversationBySid(conversationSid)
              .then(() => {
                startChat({
                  id: user?.userId.toString() || '1',
                  conversationSid,
                  chatRoomName: `Chatroom for event #${eventId}`,
                });
              })
              .catch(() => {
                joinEventConversation(eventId?.toString(), {
                  onSuccess: () => {
                    startChat({
                      id: user?.userId.toString() || '1',
                      conversationSid,
                      chatRoomName: `Chatroom for event #${eventId}`,
                    });
                  },
                });
              });
          }
        }}
        className="!p-0"
      >
        <MessageTwoTone className={clsx(color)} />
      </IconButton>
    );
  };

  return (
    <div
      className={clsx(
        className,
        'flex flex-col grow overflow-auto text-center'
      )}
    >
      <div className="flex flex-col rounded-lg bg-white relative drop-shadow-md">
        <div
          className="bg-grey-700 rounded-t-lg"
          style={{ minHeight: 150 }}
        ></div>
        <UserAvatarWrapper className="flex flex-col justify-center items-center">
          <Avatar
            className="rounded-full bg-white"
            src={eventOnwerProfile?.imageUrl || ''}
            alt="portrait"
            sx={{ width: 160, height: 160 }}
          />
        </UserAvatarWrapper>
        <div
          className="flex justify-center items-center"
          style={{ minHeight: 150 }}
        >
          <h1 className="relative text-4xl font-semibold">
            {eventOnwerProfile?.name}
          </h1>
        </div>
        <div>
          <p className="text-left my-5 ml-9 font-semibold">
            {t('event.information')}
          </p>
          <Divider />
          <div className="px-9 py-5 flex flex-row flex-wrap text-left">
            <Detail
              title="User"
              description={eventOnwerProfile?.name || ''}
              className="basis-1/2"
            />
            <Detail
              title="Status"
              description="Protection mode"
              className="basis-1/2"
            />
            <div className="p-6 w-full"></div>
            <Detail
              title="Last Seen"
              description={dateToLocaleString(
                event?.locationUpdatedAt,
                user?.locale || 'en'
              )}
              className="basis-1/2"
            />
            <Detail
              title="Gender"
              description={eventOnwerProfile?.gender?.toLowerCase()}
              className="basis-1/2 capitalize"
            />
          </div>
        </div>
        {eventOnwerProfile ? (
          <UserActionBar
            className="px-9 bg-primary"
            user={eventOnwerProfile}
            chat={<EventChat color="text-white" />}
            showChat={!event?.resolvedAt}
          />
        ) : null}
      </div>
      <Guardians event={event} chat={<EventChat />} />
    </div>
  );
};
