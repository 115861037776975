export const values = [
  {
    value: 0,
    scaledValue: 50,
    label: '50m',
  },
  {
    value: 1,
    scaledValue: 100,
    label: '100m',
  },
  {
    value: 2,
    scaledValue: 200,
    label: '200m',
  },
  {
    value: 3,
    scaledValue: 300,
    label: '300m',
  },
  {
    value: 4,
    scaledValue: 500,
    label: '500m',
  },
  {
    value: 5,
    scaledValue: 1000,
    label: '1km',
  },
  ...Array.from({ length: 90 }, (_, i) => i).map((key) => ({
    value: key + 6,
    scaledValue: (key + 1) * 100 + 1000,
    label: `${(key + 11) / 10}km`,
  })),
  {
    value: 96,
    scaledValue: 20000,
    label: '20km',
  },
  {
    value: 97,
    scaledValue: 30000,
    label: '30km',
  },
  {
    value: 98,
    scaledValue: 40000,
    label: '40km',
  },
  {
    value: 99,
    scaledValue: 50000,
    label: '50km',
  },
  {
    value: 100,
    scaledValue: 100000,
    label: '100km',
  },
];
