import styled from '@emotion/styled';

export const UserAvatarWrapper = styled.div`
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -15%);
`;

export const UserAvatar = styled.img`
  width: 160px;
`;

export const UserSquaredAvatar = styled.img`
  width: 56px;
`;
