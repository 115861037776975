import { useQuery } from 'react-query';
import { api, PaginatedApiResponse, PaginatedApiParams } from 'src/app/api';

export type Protection = {
  trackId: number;
  userId: number;
  tenantId: number;
  latitude: number;
  longitude: number;
  updatedAt: string;
};

const activeProtectionUrl = (params: PaginatedApiParams) => {
  const { page, size } = params;
  return `/track/activeprotection?page=${page}&size=${size}`;
};

export const getActiveProtectionLocations = async (
  params: PaginatedApiParams
): Promise<PaginatedApiResponse<Protection>> => {
  const response = await api.get(activeProtectionUrl(params));

  return response.data;
};

export const useActiveProtectionLocations = (params: PaginatedApiParams) => {
  return useQuery(`dangerousLocations#${activeProtectionUrl(params)}`, () =>
    getActiveProtectionLocations(params)
  );
};
