const defaultOptions: Intl.DateTimeFormatOptions = {
  // The basic format: "12/19/2012, 7:00:00 PM"
  hour12: true,
};

export const dateToLocaleString = (
  date: string | Date | null | undefined,
  locale: string | null | undefined,
  options?: Intl.DateTimeFormatOptions
): string | undefined => {
  if (!date) return;
  const dateObject = new Date(date);

  return dateObject.toLocaleString(
    locale || 'en',
    !!options ? options : defaultOptions
  );
};

export const dateWithoutTime = (date: string | null, separator = '-') => {
  if (!date) return;
  const dateObj = new Date(date);

  const month =
    dateObj?.getMonth() + 1 > 9
      ? dateObj?.getMonth() + 1
      : `0${dateObj?.getMonth() + 1}`;

  const day =
    dateObj?.getDate() > 9 ? dateObj?.getDate() : `0${dateObj?.getDate()}`;

  return [dateObj.getFullYear(), month, day].join(separator);
};

export const secondsToTime = (totalSeconds: number) => {
  const hours = Math.floor(totalSeconds / (60 * 60));

  const divisor_for_minutes = totalSeconds % (60 * 60);
  const minutes = Math.floor(divisor_for_minutes / 60);

  const divisor_for_seconds = divisor_for_minutes % 60;
  const seconds = Math.ceil(divisor_for_seconds);

  const obj = {
    h: hours,
    m: minutes,
    s: seconds,
  };
  return obj;
};

export const compareDates = (
  date1: Date | string | null | undefined,
  date2: Date | string | null | undefined,
  diff = 0
) => {
  if (!date1 && !date2) return 0;
  if (!date1) return 1;
  if (!date2) return -1;

  const dateObj1 = new Date(date1);
  const dateObj2 = new Date(date2);

  return dateObj1.getTime() + diff - dateObj2.getTime();
};
