import clsx from 'clsx';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useJsApiLoader, GoogleMap, Marker } from '@react-google-maps/api';
import { useEvents } from 'src/repos/events';
import { Report, useReports } from 'src/repos/reports';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Refresh } from '@mui/icons-material';
import { ReportDialog } from '../Reports/Dialog';
import { useActiveProtectionLocations } from 'src/repos/track';
import { Location, useDangerousLocations } from 'src/repos/locations';
import { useNotificationsContext } from '../NotificationWrapper';

type Props = {
  className?: string;
};

export const GeneralMap: FC<Props> = (props) => {
  const { className } = props;
  const { t } = useTranslation();

  const GoogleMapRef = useRef<HTMLDivElement>(null);
  const audioPlayer = useRef<HTMLAudioElement>(null);

  const [showEvents, setShowEvents] = useState(true);
  const [showReports, setShowReports] = useState(true);
  const [showDangerousLocations, setShowDangerousLocations] = useState(true);
  const [showActiveProtections, setShowActiveProtections] = useState(true);

  const { notifications } = useNotificationsContext();

  const [dangerousLocationsCircles, setDangerousLocationsCircles] = useState<
    google.maps.Circle[]
  >([]);

  const [open, setOpen] = useState(false);
  const [currentReport, setCurrentReport] = useState<Report | undefined>();
  const [libraries] = useState<
    ('places' | 'drawing' | 'geometry' | 'localContext' | 'visualization')[]
  >(['places']);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACE_API_KEY as string,
    libraries,
  });
  const [zoom, setZoom] = useState(11);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [center, setCenter] = useState({ lat: 32.1, lng: 34.929825 }); ///({ lat: 36.005817, lng: 7.567841 });
  const onLoad = useCallback(function callback(map: google.maps.Map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map: google.maps.Map) {
    setMap(null);
  }, []);

  const { data: reports, refetch: refetchReports } = useReports(
    { active: true },
    { page: 0, size: 100 }
  );
  const { data: events, refetch: refetchEvents } = useEvents(
    { active: true },
    { page: 0, size: 100 }
  );
  const {
    data: activeProtectionLocations,
    refetch: refetchAtiveProtectionLocations,
  } = useActiveProtectionLocations({ page: 0, size: 100 });

  const { data: dangerousLocations, refetch: refetchDangerousLocations } =
    useDangerousLocations({ page: 0, size: 100 });

  useEffect(() => {
    if (!showDangerousLocations) {
      if (dangerousLocationsCircles) {
        dangerousLocationsCircles.map((circle) => {
          circle.setMap(null);
        });
      }
      setDangerousLocationsCircles([]);
      return;
    }
    if (map) {
      if (dangerousLocationsCircles) {
        dangerousLocationsCircles.map((circle) => {
          circle.setMap(null);
        });
      }
      setDangerousLocationsCircles(
        dangerousLocations?.map(
          (location: Location) =>
            new google.maps.Circle({
              strokeColor: '#FF0000',
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: '#FF0000',
              fillOpacity: 0.35,
              map,
              center: { lat: location.latitude, lng: location.longitude },
              radius: location.radius,
            })
        ) || []
      );
    }
  }, [showDangerousLocations, dangerousLocations, map]);

  const refreshData = () => {
    refetchDangerousLocations();
    refetchReports();
    refetchEvents();
  };

  useEffect(() => {
    if (notifications?.length > 0) {
      const lastNoficiation = notifications.at(-1);
      switch (lastNoficiation?.data?.type) {
        case 'EVENT':
          refetchEvents();
          return;
        case 'REPORT':
          refetchReports();
        case 'PROTECTION':
          refetchAtiveProtectionLocations();
      }
    }
  }, [notifications]);

  const newEventsCount = () =>
    events?.content.reduce((acc, event) => {
      const eventDate = new Date(event.createdAt);
      return eventDate.getTime() + 60 * 60000 > new Date().getTime()
        ? acc + 1
        : acc;
    }, 0);

  const newReportsCount = () =>
    reports?.content.reduce((acc, report) => {
      const reportDate = new Date(report.createdAt);
      return reportDate.getTime() + 60 * 60000 > new Date().getTime()
        ? acc + 1
        : acc;
    }, 0);

  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  useEffect(() => {
    const interval = setInterval(refreshData, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <div
        className={clsx(
          className,
          'flex flex-col justify-center text-center rounded-lg drop-shadow-md overflow-hidden w-full h-full'
        )}
      >
        <div className="flex flex-row">
          <div className="mb-4 flex flex-row justify-start flex-wrap grow">
            <span className="flex basis-full">{t('generalMap.layers')}</span>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showEvents}
                  onChange={() => {
                    setShowEvents(!showEvents);
                  }}
                />
              }
              label={
                <div className="flex flex-row items-center">
                  <img
                    src="/locationMarker.svg"
                    alt="eventMarker"
                    className="w-8 h-8"
                  />
                  <span>
                    {t('generalMap.events', {
                      count: events?.content.length,
                      countNew: newEventsCount(),
                    })}
                  </span>
                </div>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showReports}
                  onChange={() => {
                    setShowReports(!showReports);
                  }}
                />
              }
              label={
                <div className="flex flex-row items-center">
                  <img
                    src="/reportMarker.svg"
                    alt="reportMarker"
                    className="w-6 h-6"
                  />
                  <span>
                    {t('generalMap.reports', {
                      count: reports?.content.length,
                      countNew: newReportsCount(),
                    })}
                  </span>
                </div>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showDangerousLocations}
                  onChange={() => {
                    setShowDangerousLocations(!showDangerousLocations);
                  }}
                />
              }
              label={
                <div className="flex flex-row items-center">
                  <img src="/redCircle.svg" alt="redCircle" />
                  <span>
                    {t('generalMap.dangerousLocations', {
                      count: dangerousLocations?.length,
                    })}
                  </span>
                </div>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showActiveProtections}
                  onChange={() => {
                    setShowActiveProtections(!showActiveProtections);
                  }}
                />
              }
              label={
                <div className="flex flex-row items-center">
                  <img
                    src="/activeProtectionMarker.svg"
                    alt="activeProtection"
                    className="w-6 h-6"
                  />
                  <span>
                    {t('generalMap.activeProtections', {
                      count: activeProtectionLocations?.content.length,
                    })}
                  </span>
                </div>
              }
            />
          </div>
          <div>
            <Button endIcon={<Refresh />} onClick={refreshData}>
              {t('generalMap.refresh')}
            </Button>
          </div>
        </div>
        <div
          className={clsx(
            className,
            'flex flex-col justify-center text-center rounded-lg drop-shadow-md overflow-hidden w-full h-full'
          )}
          ref={GoogleMapRef}
        >
          {isLoaded && (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={zoom}
              onLoad={onLoad}
              onUnmount={onUnmount}
              options={{
                minZoom: 3,
                maxZoom: 17,
              }}
            >
              {showReports && reports?.content.length
                ? reports?.content.map((report) => (
                    <Marker
                      key={report.reportId}
                      title={`Report #${report.reportId}`}
                      position={{ lat: report.latitude, lng: report.longitude }}
                      icon={'/reportMarker.svg'}
                      onClick={() => {
                        setOpen(true);
                        setCurrentReport(report);
                      }}
                    />
                  ))
                : null}
              {showEvents && events?.content.length
                ? events?.content.map((event) => (
                    <Marker
                      key={event.eventId}
                      title={`Event #${event.eventId}`}
                      position={{ lat: event.latitude, lng: event.longitude }}
                      icon={'/locationMarker.svg'}
                      onClick={() => {
                        window.open(`/events/${event.eventId}`, '_blank');
                      }}
                    />
                  ))
                : null}
              {showDangerousLocations &&
              dangerousLocations &&
              dangerousLocations.length
                ? dangerousLocations?.map((dangerousLocation) => (
                    <Marker
                      key={dangerousLocation.locationId}
                      title={`Dangerous location #${dangerousLocation.locationId}`}
                      position={{
                        lat: dangerousLocation.latitude,
                        lng: dangerousLocation.longitude,
                      }}
                      onClick={() => {
                        const markerBounds = new google.maps.LatLngBounds();
                        markerBounds.extend(
                          new google.maps.LatLng(
                            dangerousLocation.latitude,
                            dangerousLocation.longitude
                          )
                        );
                        map?.fitBounds(markerBounds);
                      }}
                    />
                  ))
                : null}
              {showActiveProtections &&
              activeProtectionLocations?.content.length
                ? activeProtectionLocations?.content.map(
                    (activeProtectionLocation) => (
                      <Marker
                        key={activeProtectionLocation.trackId}
                        title={`User #${activeProtectionLocation.userId} location`}
                        position={{
                          lat: activeProtectionLocation.latitude,
                          lng: activeProtectionLocation.longitude,
                        }}
                        icon={'/activeProtectionMarker.svg'}
                        onClick={() => {
                          const markerBounds = new google.maps.LatLngBounds();
                          markerBounds.extend(
                            new google.maps.LatLng(
                              activeProtectionLocation.latitude,
                              activeProtectionLocation.longitude
                            )
                          );
                          map?.fitBounds(markerBounds);
                        }}
                      />
                    )
                  )
                : null}
            </GoogleMap>
          )}
        </div>
      </div>
      <ReportDialog
        open={open}
        report={currentReport}
        onClose={() => {
          setOpen(false);
          setCurrentReport(undefined);
        }}
      />
      <audio ref={audioPlayer} src={'/notification-126507.mp3'} />
    </>
  );
};
