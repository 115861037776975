import { CallEndTwoTone, CallTwoTone, Close } from '@mui/icons-material';
import {
  Avatar,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { Call } from '@twilio/voice-sdk';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { secondsToTime } from 'src/app/utils';
import { useAvatars, Avatar as AvatarType } from 'src/repos/avatar';
import { useTwilioIdentity } from 'src/repos/twilio';
import { DialogStyled } from '../Chat/styles';

type Props = {
  open: boolean;
  onClose: () => void;
  setSelected: (avatar: AvatarType) => void;
};

export const AvatarDialog: FC<Props> = (props) => {
  const { onClose, open, setSelected } = props;
  const { t } = useTranslation();

  const { data: avatars } = useAvatars();

  return (
    <DialogStyled
      onClose={() => {
        onClose();
      }}
      open={open}
    >
      <DialogTitle className="text-center flex flex-row justify-between items-center">
        <p>{t('settings.avatar')}</p>
        {onClose ? (
          <IconButton aria-label="close" onClick={onClose}>
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent dividers className="flex flex-row flex-wrap p-8">
        {avatars?.map((avatar) => (
          <Avatar
            key={avatar.key}
            className="p-2 cursor-pointer"
            onClick={() => {
              setSelected(avatar);
              onClose();
            }}
            alt={avatar.key || ''}
            src={avatar.url || ''}
            sx={{ width: 92, height: 92 }}
          />
        ))}
      </DialogContent>
    </DialogStyled>
  );
};
