import { Sticky } from './styles';
import { UserMenu } from './components/UserMenu';
import { NotificationsMenu } from './components/NotificationsMenu';

export const Navbar = () => {
  return (
    <Sticky className="bg-grey-500 flex flex-row justify-between items-center py-6 px-10 h-[90px] top-0 ltr:left-[90px] fixed">
      <img src="/appTitle.png" alt="logo" />
      <div className="flex flex-row items-center">
        {/* <Paper component="form" className="flex flex-row !rounded-xl h-10">
          <InputBase
            className="py-2 px-5"
            placeholder={t('general.search') as string}
            inputProps={{ 'aria-label': 'Search' }}
          />
          <Divider className="!h-10" orientation="vertical" />
          <IconButton className="!bg-white" disableRipple>
            <Search className="text-grey-700" />
          </IconButton>
        </Paper> */}
        <NotificationsMenu />
        <UserMenu />
      </div>
    </Sticky>
  );
};
