import { TFunction } from 'i18next';
import { object, string, date } from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';

export const schema = (t: TFunction) => {
  const notAValidEmail = t('login.errorMessages.notAValidEmail');
  const invalidPhone = t('settings.errorMessages.invalidPhone');
  const required = t('login.errorMessages.fieldIsRequired');

  return object().shape({
    name: string().required(required),
    email: string().email(notAValidEmail).required(required),
    phoneNumber: string().test(
      'validatePhoneNumber',
      invalidPhone,
      (valueToValidate) => {
        return (
          valueToValidate == null ||
          isValidPhoneNumber(valueToValidate as string)
        );
      }
    ),
    birthday: date(),
  });
};
