import clsx from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  title: string;
  description: string | undefined | null;
  className?: string;
};

export const Detail: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { title, description, className } = props;

  return (
    <div className={clsx(className, 'flex flex-col')}>
      <p className="text-grey-700 mb-1 font-light">{title}</p>
      {description ? (
        <p className="text-secondary font-medium">{description}</p>
      ) : (
        <p className="text-gray-500 font-light italic">
          {t('general.unknown')}
        </p>
      )}
    </div>
  );
};
