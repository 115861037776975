import {
  HelpOutlineOutlined,
  LockOutlined,
  PersonOutlineOutlined,
  PowerSettingsNew,
  SettingsOutlined,
} from '@mui/icons-material';
import {
  Avatar,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser } from 'src/repos/users';
import { auth } from '../../../../config/firebase';

export const UserMenu: FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { data: user } = useCurrentUser();

  const logoutOfApp = () => {
    auth.signOut();
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Tooltip title="Account settings">
        <IconButton
          className="!p-0"
          onClick={handleClick}
          size="small"
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar src={user?.imageUrl || ''} alt="profile-avatar"></Avatar>
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={() => {
            navigate('/settings');
          }}
        >
          <ListItemIcon>
            <PersonOutlineOutlined fontSize="small" />
          </ListItemIcon>
          {t('general.profile')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate('/settings');
          }}
        >
          <ListItemIcon>
            <SettingsOutlined fontSize="small" />
          </ListItemIcon>
          {t('settings.title')}
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <HelpOutlineOutlined fontSize="small" />
          </ListItemIcon>
          {t('general.help')}
        </MenuItem>
        <MenuItem onClick={(e) => logoutOfApp()}>
          <ListItemIcon>
            <LockOutlined fontSize="small" />
          </ListItemIcon>
          {t('general.lock')}
        </MenuItem>
        <Divider />
        <MenuItem onClick={(e) => logoutOfApp()}>
          <ListItemIcon>
            <PowerSettingsNew className="text-red-500" />
          </ListItemIcon>
          <span className="text-red-500">{t('general.logOut')}</span>
        </MenuItem>
      </Menu>
    </>
  );
};
